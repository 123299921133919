import React, { useRef } from "react";
import {
  Box,
  Typography,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import { adjustNumberForRightPanel } from "../utils/pileUtils";

function PileValue(props) {
  const { data, setData, config, inputError, setInputError, disabled } = props;
  // Ref
  const actualValueRef = useRef("");
  const handleTextFieldChange = () => {
    actualValueRef.current.value === ""
      ? setInputError({ ...inputError, soil_depth_actual: true })
      : setInputError({ ...inputError, soil_depth_actual: false });
  };

  const actualValueComponent = (config) => {
    return config.isActualTextField ? (
      <TextField
        id="outlined-number"
        variant="outlined"
        type="number"
        InputProps={{
          endAdornment: <InputAdornment position="end">mm</InputAdornment>,
        }}
        fullWidth
        size="small"
        inputRef={actualValueRef}
        value={isNaN(data.props[config.actual]) ? "" : data.props[config.actual]}
        disabled={disabled}
        onWheel={ event => event.target.blur() }
        onChange={(e) => {
          setData({
            ...data,
            props: {
              ...data.props,
              [config.actual]: parseFloat(e.target.value),
            },
          });
          if (config.required) {
            handleTextFieldChange();
          }

        }}
      />
    ) : (
      <Typography variant="body2" align="right">
        {adjustNumberForRightPanel(data.props[config.actual])}mm
      </Typography>
    );
  };
  
  return (
    <Box>
      <Typography variant="subtitle1">{config.title}</Typography>
      <Grid container>
        <Grid item xs={4}>
          <Typography variant="body2">設計時：</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body2" align="right">
            {adjustNumberForRightPanel(data.props[config.design])}mm
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body2">計画時：</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body2" align="right">
            {adjustNumberForRightPanel(data.props[config.plan])}mm
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={4}>
          <Typography variant="body2">実施時{config.required? "*" : ""}：</Typography>
        </Grid>
        <Grid item xs={8}>
          {actualValueComponent(config)}
        </Grid>
      </Grid>
    </Box>
  );
}

export default PileValue;
