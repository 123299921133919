import Encoding from "encoding-japanese";
import JSZip from "jszip";
// Utils
import { ignorePileExport } from "./pileUtils";
// Config
import {
  pileJpnHeader,
  pileExportHeader,
  meshJpnHeader,
  meshExportHeader,
} from "../config/CsvConfig";
import { scale } from "../config/ViewerConfig";
// Types
import { MeshCSVType, MeshGeometryType } from "../../types/mesh.types";
import { PileCSVType, PileDataType, PileType } from "../../types/pile.types";

const csvFileToObjectArray = async (file: File, fileType: string = ""): Promise<MeshCSVType[] | PileCSVType[] | any[]> => {
  const fileReader = new FileReader();
  if (file) {
    fileReader.readAsArrayBuffer(file);
    await new Promise<void>((resolve) => (fileReader.onload = () => resolve()));
    if (fileReader.error) {
      throw fileReader.error;
    }
    if (fileReader.result === null) {
      throw new Error("File reader result is null");
    }
    const csvOutput = fileReader.result as ArrayBuffer;
    var codes = new Uint8Array(csvOutput);
    // unicodeに変換
    var encoding = Encoding.detect(codes);
    var unicodeString = Encoding.convert(codes, {
      to: "unicode",
      from: encoding,
      type: "string",
    });
    let keys: string[];
    switch (fileType) {
      case "mesh":
        keys = meshExportHeader;
        return csvFileToArray(unicodeString, keys) as MeshCSVType[];
      case "pile":
        keys = pileExportHeader;
        return csvFileToArray(unicodeString, keys) as PileCSVType[];
      default:
        keys = [];
        return csvFileToArray(unicodeString, keys) as any[];
    }
  } else {
    return [];
  }
};

const csvFileToArray = (string: string, keys: string[] = []) => {
  const csvHeader = getHeader(string);
  if (keys.length > 0 && !validateKeys(csvHeader, keys)) {
    throw new Error("Invalid keys");
  }
  const csvRows = getRows(string);
  const array = csvRows
    .map((i: string) => {
      // 行が空白でない時
      if (i) {
        const values = i.split(/,|\r/);
        // const splitValues = i.match(/"[^"]*"|[^,]+/g);
        // console.log(splitValues);
        // const values = splitValues.map((item) => {
        //   item = item.replace(/"/g, '');
        //   item = item.replace(/,/g, '');
        //   item = item.split('(').join('-');
        //   item = item.split(')').join('');
        //   item = item.split(' ').join('');
        //   return item;
        // });
        const obj = csvHeader.reduce((object: { [key: string]: string }, header: string, index: number) => {
          object[header] = values[index];
          return object;
        }, {});
        return obj;
      }
    })
    .filter((e) => e);
  return array;
};

const getHeader = (string: string) => {
  // 日本語のヘッダーを取り除く
  const fromSecondLine = string.slice(string.indexOf("\n") + 1);
  const header = fromSecondLine
    .slice(0, fromSecondLine.indexOf("\n"))
    .split(/,|\r/)
    .filter((item) => item !== "");
  return header;
};

const getRows = (string: string): string[] => {
  // 日本語のヘッダーを取り除く
  const fromSecondLine = string.slice(string.indexOf("\n") + 1);
  const rows = fromSecondLine
    .slice(fromSecondLine.indexOf("\n") + 1)
    .split("\n");
  return rows;
};

const downloadCsv = (data: any, fileName: string) => {
  const finalFileName = fileName.endsWith(".csv")
    ? fileName
    : `${fileName}.csv`;
  const a = document.createElement("a");
  // shift-jisに変換
  var encoding = Encoding.detect(data);
  const str = Encoding.stringToCode(data);
  var unicodeString = Encoding.convert(str, {
    to: "sjis",
    from: encoding,
  });
  const u8a = new Uint8Array(unicodeString);
  a.href = URL.createObjectURL(new Blob([u8a], { type: "text/csv" }));
  a.setAttribute("download", finalFileName);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

const downloadZip = (dataList: any[], projectName: string, fileName: string) => {
  var zip = new JSZip();
  const exportProjectName = fileName.endsWith(".zip")
    ? projectName
    : `${projectName}.zip`;
  const exportFileNames = ["地盤調査結果.csv", `${fileName}`];
  const a = document.createElement("a");
  dataList.forEach((data, index) => {
    // shift-jisに変換
    var encoding = Encoding.detect(data);
    const str = Encoding.stringToCode(data);
    var unicodeString = Encoding.convert(str, {
      to: "sjis",
      from: encoding,
    });
    const u8a = new Uint8Array(unicodeString);
    zip.file(exportFileNames[index], u8a);
  });
  zip.generateAsync({ type: "blob" }).then(function (content) {
    a.href = URL.createObjectURL(
      new Blob([content], { type: "application/zip" })
    );
    a.setAttribute("download", exportProjectName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  });
};

const downloadText = (data: BlobPart, fileName: string) => {
  const finalFileName = fileName.endsWith(".txt")
    ? fileName
    : `${fileName}.txt`;
  const a = document.createElement("a");
  a.href = URL.createObjectURL(new Blob([data], { type: "text/plain" }));
  a.setAttribute("download", finalFileName);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

// 杭一覧用にpropsを取得
const getPileProps = (piles: PileDataType[]) => {
  // headerを取得
  const headers = Object.keys(piles[0].props);
  // 不要なカラムを削除
  const exportHeaders = headers.filter((header) => {
    return !ignorePileExport.includes(header);
  });
  // 各杭の値を配列化
  const data = piles.map((pile) => {
    const newPileData: { [key: string]: string | number | boolean | Date | undefined } = {};
    exportHeaders.map((header) => {
      switch (header) {
        case "date_of_work": {
          newPileData[header] = dateToStr(pile.props[header]);
          break;
        }
        case "has_middle_plan": {
          newPileData[header] = pile.props[header] ? "有" : "無";
          break;
        }
        default:
          newPileData[header] = Number.isNaN(pile.props[header as keyof PileType])
            ? ""
            : pile.props[header as keyof PileType];
      }
    });
    return newPileData;
  });
  return data;
};

// 杭データをCSVに変換
const pileToCsv = (piles: PileDataType[]) => {
  // headerを取得
  const headers = Object.keys(piles[0].props);
  // 不要なカラムを削除
  const exportHeaders = headers.filter((header) => {
    return !ignorePileExport.includes(header);
  });
  // 各杭の値を配列化
  const data = piles.map((pile) => {
    const values = exportHeaders.map((header) => {
      switch (header) {
        case "date_of_work": {
          return dateToStr(pile.props[header]);
        }
        case "has_middle_plan": {
          return pile.props[header] ? "有" : "無";
        }
        default:
          return Number.isNaN(pile.props[header as keyof PileType]) ? "" : pile.props[header as keyof PileType];
      }
    });
    return values;
  });
  // 文字列に変換
  const data_text = [pileJpnHeader, exportHeaders, ...data]
    .map((e) => e.join(","))
    .join("\n");
  return data_text;
};

// 地盤データをCSVに変換
const meshToCsv = (meshGeometry :MeshGeometryType) => {
  // 初期ポイント
  const points = meshGeometry.initialPoints;
  const data = points.map((point) => [
    point.x * scale,
    point.y * scale,
    point.z * scale,
  ]);
  // 文字列に変換
  const data_text = [meshJpnHeader, meshExportHeader, ...data]
    .map((e) => e.join(","))
    .join("\n");
  return data_text;
};

// InvalidDateを判定
const isInvalidDate = (date:Date) => Number.isNaN(date.getTime());

// 8桁の日付YYYYMMDDをDate型に変換
const strToDate = (date:string) => {
  if (date.length === 8) {
    const year = Number(date.substring(0, 4));
    const month = Number(date.substring(4, 6)) - 1;
    const day = Number(date.substring(6, 8));
    return new Date(year, month, day);
  } else {
    return new Date("");
  }
};

// Date型を8桁の日付YYYYMMDDに変換
const dateToStr = (date: Date) => {
  if (isInvalidDate(date)) {
    return "";
  } else {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}${month < 10 ? `0${month}` : month}${
      day < 10 ? `0${day}` : day
    }`;
  }
};

// Date型を日本語の日付に変換
const dateToStrforAnnotation = (date: Date) => {
  if (isInvalidDate(date)) {
    return "";
  } else {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}/${month < 10 ? `0${month}` : month}/${
      day < 10 ? `0${day}` : day
    }`;
  }
};

// 最新の日付を取得
const getLatestDate = (piles: PileDataType[]) => {
  const dates = piles.map((pile) => pile.props.date_of_work);
  const latestDate = dates.reduce((latest, date) => {
    if (isInvalidDate(date)) {
      return latest;
    } else if (isInvalidDate(latest)) {
      return date;
    } else if (date > latest) {
      return date;
    } else {
      return latest;
    }
  }, new Date(""));
  return latestDate;
};

// ファイル名を生成
const createFileNamebyDate = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();
  return `${year}${month < 10 ? `0${month}` : month}${
    day < 10 ? `0${day}` : day
  }-${hour < 10 ? `0${hour}` : hour}${minute < 10 ? `0${minute}` : minute}${
    second < 10 ? `0${second}` : second
  }`;
};

// "YYYY/MM/DD HH:mm:ss"の日付を"YYYYMMDD-HHmmSS"に変換
const convertDateStringToCSVFilename = (date:string) => {
  const year = date.substring(0, 4);
  const month = date.substring(5, 7);
  const day = date.substring(8, 10);
  const hour = date.substring(11, 13);
  const minute = date.substring(14, 16);
  const second = date.substring(17, 19);
  return `${year}${month}${day}-${hour}${minute}${second}.csv`;
};

// nullをNaNに変換
function replaceNullWithNaN(obj: Record<string, any>): Record<string, any> {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (obj[key] === null) {
        obj[key] = NaN;
      } else if (typeof obj[key] === "object") {
        replaceNullWithNaN(obj[key]); // ネストされたオブジェクトも再帰的に処理
      }
    }
  }
  return obj;
}

const validateKeys = (headers: string[], keys: string[]) => {
  return keys.every((key) => headers.includes(key));
};

export {
  csvFileToObjectArray,
  csvFileToArray,
  downloadCsv,
  downloadZip,
  downloadText,
  pileToCsv,
  meshToCsv,
  strToDate,
  dateToStr,
  dateToStrforAnnotation,
  getLatestDate,
  isInvalidDate,
  createFileNamebyDate,
  replaceNullWithNaN,
  convertDateStringToCSVFilename,
  validateKeys,
  getPileProps,
};
