import { useState, useContext, useEffect } from "react";
import * as React from "react";
import { styled } from "@mui/material/styles";
import { darkTheme } from "../darkTheme";
import {
  ThemeProvider,
  Card,
  CardContent,
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Divider,
  IconButton,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { TableVirtuoso } from "react-virtuoso";
// Contexts
import ObjectContext from "../contexts/ObjectContext";
// Utils
import { getPileProps, isInvalidDate } from "../utils/csvUtils";
import { getNewestValue } from "../utils/pileUtils";
// Config
import {
  pileHeaderConfig,
} from "../config/CsvConfig";

const minWidth = "calc(100vw - 20px)";
const minHeight = "calc(100vh - 20px - 64px - 85px)";

const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table
      {...props}
      size="small"
      sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
    />
  ),
  TableHead,
  TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
  TableBody: React.forwardRef((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

function fixedHeaderContent() {
  return (
    <TableRow>
      {pileHeaderConfig.map((column) => (
        <TableCell
          key={column.dataKey}
          variant="head"
          align={column.numeric || false ? "right" : "left"}
          style={{ width: column.width }}
          sx={{
            backgroundColor: "background.paper",
          }}
        >
          {column.label}
        </TableCell>
      ))}
    </TableRow>
  );
}

function rowContent(_index, row) {
  return (
    <React.Fragment>
      {pileHeaderConfig.map((column) => {
        const value = row[column.dataKey];
        return (
          <TableCell
            key={column.dataKey}
            align={column.numeric || false ? "right" : "left"}
          >
            {column.numeric && !Number.isNaN(value)
              ? column.format(value)
              : value}
          </TableCell>
        );
      })}
    </React.Fragment>
  );
}
//

const PileTablePageContainer = styled("div")({
  width: "calc(100vw - 20px)",
  minWidth: minWidth,
  minHeight: minHeight,
  // maxWidth: maxWidth,
  // height: "calc(100vw - 20px - 64px)",
  position: "fixed",
  float: "right",
  zIndex: "1",
  margin: "10px",
  top: 64,
  bottom: 0,
  left: 0,
  right: 0,
  // boxSizing: 'border-box',
});

const PileTablePage = (props) => {
  // State
  const [rows, setRows] = useState([]);
  const [pileStatus, setPileStatus] = useState({
    completed: 0,
    uncompletedLength: 0,
  });
  // Contexts
  const { objectProvided } = useContext(ObjectContext);

  // userEffect
  useEffect(() => {
    if (objectProvided.piles.length > 0) {
      const rows = getPileProps(objectProvided.piles);
      setRows(rows);
      const uncompleted = objectProvided.piles.filter((pile) =>
        isInvalidDate(pile.props.date_of_work)
      );
      const uncompletedLength = uncompleted.reduce(
        (sum, pile) => sum + getNewestValue(pile.props, "pile_length"),
        0
      );
      setPileStatus({
        completed: objectProvided.piles.length - uncompleted.length,
        uncompletedLength: uncompletedLength,
      });
    }
  }, [objectProvided]);

  const handleClose = () => {
    props.setOpen(true);
  };

  return (
    <ThemeProvider theme={darkTheme}>
      {!props.open && (
        <PileTablePageContainer>
          <Card sx={{ minWidth: minWidth }}>
            <CardContent>
              <Stack spacing={0.2}>
                <Grid container spacing={2}>
                  <Grid item xs={10}>
                    <Typography variant="subtitle1">杭リスト</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Box display="flex" justifyContent="flex-end">
                      <IconButton onClick={handleClose}>
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1">
                      全数: {objectProvided.piles.length} 本 （ 施工済:{" "}
                      {pileStatus.completed} 本 ／ 未施工:{" "}
                      {objectProvided.piles.length - pileStatus.completed} 本 ）
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1">
                      未施工杭総長：
                      {(pileStatus.uncompletedLength / 1000).toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2,
                        }
                      )}
                      m
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
                <Paper
                  sx={{
                    width: "100%",
                    height: "calc(100vh - 20px - 64px - 85px)",
                  }}
                >
                  <TableVirtuoso
                    data={rows}
                    components={VirtuosoTableComponents}
                    fixedHeaderContent={fixedHeaderContent}
                    itemContent={rowContent}
                  />
                </Paper>
              </Stack>
            </CardContent>
          </Card>
        </PileTablePageContainer>
      )}
    </ThemeProvider>
  );
};

export default PileTablePage;
