import { useContext, useState, useEffect } from "react";
// MUI
import { Button, Menu, MenuItem, Divider } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// Components
import PileTablePage from "./PileTablePage";
import UpdatePage from "../UpdatePage";
// Utils
import {
  pileToCsv,
  meshToCsv,
  downloadZip,
  convertDateStringToCSVFilename,
} from "../utils/csvUtils";
import { exportMesh } from "../utils/meshUtils";
import { replaceNullWithNaN } from "../utils/csvUtils";
import { createMeshPoints } from "../utils/meshUtils";
import { PileInputToPile } from "../utils/pileUtils";
import { createPileGeometry } from "../utils/objectUtils";
// Contexts
import ProjectContext from "../contexts/ProjectContext";
import ObjectContext from "../contexts/ObjectContext";
import ProgressContext from "../contexts/ProgressContext";
// Amplify
import { Auth } from "aws-amplify";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { API } from "aws-amplify";
import {
  getProjectById,
  getMeshByProjectId,
  listPileSetsByProjectId,
  getPilesByPileSetId,
} from "../../graphql/queries";

const ProjectInfoMenu = () => {
  // Contexts
  const { projectProvided, setProjectProvided } = useContext(ProjectContext);
  const { objectProvided, setObjectProvided } = useContext(ObjectContext);
  const { setProgressProvided } = useContext(ProgressContext);
  // Auth
  const { user } = useAuthenticator((context) => [context.user]);
  const [isEditableUser, setIsEditableUser] = useState(false);
  //   State
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onExport = () => {
    const pileData = pileToCsv(objectProvided.piles);
    const meshData = meshToCsv(objectProvided.mesh);
    const projectName = projectProvided.project.name;
    const fileName = convertDateStringToCSVFilename(
      projectProvided.selectedPile.name
    );
    downloadZip([meshData, pileData], projectName, fileName);
  };
  const onMeshExport = () => {
    const meshGeometry = objectProvided.mesh;
    exportMesh(meshGeometry.geometry, projectProvided.project.name);
  };
  const [pileTableOpen, setPileTableOpen] = useState(true);
  const onPileTable = () => {
    setPileTableOpen(!pileTableOpen);
    handleClose();
  };
  //  Update PileSet Page
  const [pileSetPageOpen, setPileSetPageOpen] = useState(false);
  const onAddPileSet = () => {
    setPileSetPageOpen(true);
    handleClose();
  };
  const showPileSetPage = () => {
    if (pileSetPageOpen) {
      return (
        <UpdatePage
          initialStatus="newPileSet"
          open={pileSetPageOpen}
          setOpen={setPileSetPageOpen}
        />
      );
    }
  };
  //  Update Page
  const [meshPageOpen, setmeshPageOpen] = useState(false);
  const onAddNewMesh = () => {
    setmeshPageOpen(true);
    handleClose();
  };
  const showMeshPage = () => {
    if (meshPageOpen) {
      return (
        <UpdatePage
          initialStatus="updateMesh"
          open={meshPageOpen}
          setOpen={setmeshPageOpen}
        />
      );
    }
  };
  // Get new data from server
  const onGetNewData = async () => {
    setProgressProvided(true);
    const projectId = projectProvided.project.id;
    // get Project
    const projectRes = await API.graphql({
      query: getProjectById,
      variables: { projectId },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    // get PileSet data from DB
    const pileSets = await API.graphql({
      query: listPileSetsByProjectId,
      variables: { projectId },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    if (pileSets.data.listPileSetsByProjectId === null) {
      return;
    }
    const meshRes = (await API.graphql({
      query: getMeshByProjectId,
      variables: { projectId },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    }));
    // get Pile data from DB
    const pileSet = pileSets.data.listPileSetsByProjectId[0];
    const pilesRes = await API.graphql({
      query: getPilesByPileSetId,
      variables: {
        projectId,
        pileSetId: pileSet.id,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    if (pilesRes.data.getPilesByPileSetId === null) {
      return;
    }
    // update Project Context
    setProjectProvided({
      ...projectProvided,
      pileFiles: pileSets.data.listPileSetsByProjectId,
      isPileNewest: true,
      selectedPile: pileSet,
    });
    // update ProjectSettings Context
    const projectSettings = projectRes.data.getProjectById.settings;
    // create mesh points
    const initialMeshPoints =
      meshRes.data.getMeshByProjectId !== null
        ? createMeshPoints(meshRes.data.getMeshByProjectId.points)
        : [];
    const pilesObjectArray = pilesRes.data.getPilesByPileSetId.map((pile) => {
      return replaceNullWithNaN(pile);
    });
    const piles = PileInputToPile(pilesObjectArray);
    // create pile object
    const pilePoints = piles.map((pile) => {
      return { props: pile };
    });
    const { pileObjectArray: pileObjectArrayWithInfo, pointsAddToMesh } =
      createPileGeometry(pilePoints);
      setObjectProvided({
        type: "openProject",
        payload: {
          pileMarginOption: {
            method: projectSettings.pileIndexParam,
            param1: projectSettings.pileParamA,
            param2: projectSettings.pileParamB,
            param3: projectSettings.pileParamC,
            param4: projectSettings.pileParamD,
          },
          pileFilter: {
            range: [
              projectSettings.extraLengthParamA,
              projectSettings.extraLengthParamB,
              projectSettings.extraLengthParamC,
            ],
            visibleStatus: projectSettings.extraLengthVisible,
          },
          meshColor: {
            colorMap: projectSettings.meshColor,
            method: projectSettings.meshIndexParam,
          },
          mesh: {
            initialPoints: initialMeshPoints,
            pointsAddToMesh: pointsAddToMesh,
          },
          piles: pileObjectArrayWithInfo,
        },
      });
    setProgressProvided(false);
    handleClose();
    // Get new data from server
  };

  useEffect(() => {
    Auth.currentSession().then((user) => {
      const { payload } = user.getIdToken();
      if (payload && payload["cognito:groups"]) {
        setIsEditableUser(
          payload["cognito:groups"].includes(
            "User_" + projectProvided.project.id
          ) || payload["cognito:groups"].includes("Admin")
        );
      }
    });
  }, [user, projectProvided]);
  return (
    <div>
      <Button
        variant="outlined"
        size="small"
        color="inherit"
        onClick={handleMenu}
        endIcon={<KeyboardArrowDownIcon />}
      >
        データ管理
      </Button>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        elevation={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={onGetNewData}>
          サーバー上の最新データを読み込む
        </MenuItem>
        <Divider />
        <MenuItem onClick={onPileTable}>杭リストを表示</MenuItem>
        <Divider />
        <MenuItem onClick={onExport}>CSVエクスポート</MenuItem>
        <Divider />
        {isEditableUser && (
          <>
            <MenuItem onClick={onAddPileSet}>杭リストCSVをインポート</MenuItem>
            <MenuItem onClick={onAddNewMesh}>
              地盤データCSVをインポート
            </MenuItem>
          </>
        )}
        <Divider />
        <MenuItem onClick={onMeshExport}>地盤モデルをエクスポート</MenuItem>
      </Menu>
      <PileTablePage open={pileTableOpen} setOpen={setPileTableOpen} />
      {showPileSetPage()}
      {showMeshPage()}
    </div>
  );
};

export default ProjectInfoMenu;
