import { createContext, useReducer, FC, ReactNode } from "react";
// Types
import { Project } from "../../types/project.types";
type Props = { children: ReactNode };
type ActionType = Project;
type ProjectContextValue = {
  projectProvided: Project;
  setProjectProvided: React.Dispatch<ActionType>;
};

const ProjectContext = createContext<ProjectContextValue>({
  projectProvided: {} as Project,
  setProjectProvided: () => {},
});

const initialProject: Project = {
  project: undefined,
  auth: false,
  pileFiles: [],
  isPileNewest: true,
  selectedPile: "",
};
const ProjectReducer = (initialProject: Project, action: ActionType): Project => {
  if (action) {
    return action;
  } else {
    return initialProject;
  }
};

const ProjectProvider: FC<Props> = ({ children }) => {
  const [project, setProject] = useReducer(ProjectReducer, initialProject);
  return (
    <ProjectContext.Provider
      value={{
        projectProvided: project,
        setProjectProvided: setProject,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export default ProjectContext;
export { ProjectProvider, initialProject };
