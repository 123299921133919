import React, { useState, useEffect, useContext, Fragment } from "react";
import {
  Autocomplete,
  TextField,
  CircularProgress,
  Typography,
} from "@mui/material";
import { initialWorkArea } from "../config/PileConfig";
// Contexts
import ObjectContext from "../contexts/ObjectContext";
// Types
import { WorkAreaType } from "../../types/pile.types";

const WorkArea = () => {
  // Contexts
  const { objectProvided, setObjectProvided } = useContext(ObjectContext);
  // State
  const [selectedValue, setSelectedValue] = useState(initialWorkArea[0]);
  // Autocomplete
  const [open, setOpen] = useState(false);
  const loading = false;
  // Props
  const defaultProps = {
    options: objectProvided.workArea,
    getOptionLabel: (option: WorkAreaType) => option.name,
  };

  // WorkAreaの初期値を設定
  useEffect(() => {
    if (objectProvided.workArea.length > 0) {
      setSelectedValue(objectProvided.selectedWorkArea);
    }
  }, [objectProvided]);
  return (
    <>
      <Autocomplete
        {...defaultProps}
        id="autocomplete-workarea-list"
        value={selectedValue}
        disableClearable
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        isOptionEqualToValue={(option, value) => option.index === value.index}
        loading={loading}
        noOptionsText={"工区がありません"}
        renderInput={(params) => (
          <TextField
            {...params}
            label="工区を選択"
            variant="standard"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </Fragment>
              ),
            }}
          />
        )}
        renderOption={(props, option) => (
          <li {...props}>
            <Typography>{option.name}</Typography>
          </li>
        )}
        onChange={async(event, newValue) => {
          setSelectedValue(newValue);
          setObjectProvided({
            type: "setSelectedWorkArea",
            payload: newValue,
          });
        }}
      />
    </>
  );
};

export default WorkArea;
