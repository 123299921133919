import React, { useState, useEffect } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { darkTheme } from "./darkTheme";
import {
  ThemeProvider,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
// Icons
import CloseIcon from '@mui/icons-material/Close';

import TopPageOption from "./items/TopPageOption";
import NewProject from "./items/NewProject";

const TopPageDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const TopPageDialogTitle = (props: { [x: string]: any; children: any, onClose: any }) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
    </DialogTitle>
  );
};

TopPageDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const TopPage = ({ initialStatus = "option"}) => {
  const [open, setOpen] = useState(true);
  const [status, setStatus] = useState(initialStatus);
  const { user } = useAuthenticator((context) => [context.user]);
  useEffect(() => {
    Auth.currentSession().then((user) => {
      const { payload } = user.getIdToken();
      if (payload && payload["cognito:groups"]) {
        setOpen(payload["cognito:groups"].includes("Admin"));
      }
    });
  }, [user]);

  const handleClick = (value: string) => {
    switch (value) {
      case "option":
        setStatus("option");
        break;
      case "new":
        setStatus("new");
        break;
      case "close":
        setOpen(false);
        break;
      default:
        setOpen(false);
        break;
    }
  };

  const handleCloseClick = () => {
    handleClick("close");
  }

  const item = () => {
    switch (status) {
      case "option":
        return <TopPageOption handleClick={handleClick} />;
      case "new":
        return <NewProject handleClick={handleClick} />;
      default:
        return <div>default</div>;
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <TopPageDialog
        fullWidth
        maxWidth={"sm"}
        onClose={() => {}}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <TopPageDialogTitle id="customized-dialog-title" onClose={() => {}}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="title">ANAGO Mobile</Typography>
            </Grid>
            <Grid item>
              <IconButton size="small">
                <CloseIcon onClick={handleCloseClick}/>
              </IconButton>
            </Grid>
          </Grid>
        </TopPageDialogTitle>
        <Divider />
        {item()}
      </TopPageDialog>
    </ThemeProvider>
  );
};

export default TopPage;
