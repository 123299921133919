import { createContext, useReducer, FC, ReactNode } from "react";
import { CameraType } from "../../types/viewer.types";
// Types
type Props = {
  children: ReactNode;
};
type ActionType = CameraType;
type CameraContextType = {
  cameraProvided: CameraType;
  setCameraProvided: React.Dispatch<ActionType>;
};

const CameraContext = createContext<CameraContextType>({
  cameraProvided: "" as CameraType,
  setCameraProvided: () => {},
});

const initialCamera: CameraType = "perspective";
const cameraReducer = (
  initialCamera: CameraType,
  action: ActionType
): CameraType => {
  if (action) {
    return action;
  } else {
    return initialCamera;
  }
};

const CameraProvider: FC<Props> = ({ children }) => {
  const [camera, setCamera] = useReducer(cameraReducer, initialCamera);
  return (
    <CameraContext.Provider
      value={{
        cameraProvided: camera,
        setCameraProvided: setCamera,
      }}
    >
      {children}
    </CameraContext.Provider>
  );
};

export default CameraContext;
export { CameraProvider, initialCamera };
