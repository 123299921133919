import { createContext, useReducer, useContext, FC, ReactNode } from "react";
// Types
type Props = {
  children: ReactNode;
};
type PilePanelContextType = boolean;
type ActionType = boolean;
type PilePanelContextValue = {
  pilePanelProvided: PilePanelContextType;
  setPilePanelProvided: React.Dispatch<ActionType>;
};

const PilePanelContext = createContext<PilePanelContextValue>({
  pilePanelProvided: false as PilePanelContextType,
  setPilePanelProvided: () => {},
});

const initialPilePanel: PilePanelContextType = true;
const pilePanelReducer = (
  initialPilePanel: PilePanelContextType,
  action: ActionType
): PilePanelContextType => {
  if (typeof action === typeof true) {
    return action;
  } else {
    return initialPilePanel;
  }
};

const PilePanelProvider: FC<Props> = ({ children }) => {
  const [pilePanel, setPilePanel] = useReducer(
    pilePanelReducer,
    initialPilePanel
  );
  return (
    <PilePanelContext.Provider
      value={{
        pilePanelProvided: pilePanel,
        setPilePanelProvided: setPilePanel,
      }}
    >
      {children}
    </PilePanelContext.Provider>
  );
};

const usePileContext = () => {
  return useContext(PilePanelContext);
};

export default PilePanelContext;
export { PilePanelProvider, initialPilePanel };
export { usePileContext };
