import React from "react";
import { useRef, useState, useContext } from "react";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";
import { Instances, Instance } from "@react-three/drei";
import { grey } from "@mui/material/colors";

import {
  getPileAnnotation,
  changePileColorByDate,
  darkenColor,
} from "../utils/pileUtils";
import {
  dateToStrforAnnotation,
  getLatestDate,
  isInvalidDate,
} from "../utils/csvUtils";
import { names, colors } from "../config/PileConfig";
import Annotation from "./Annotation";
import Pin from "./Pin";

// Context
import PilePanelContext from "../contexts/PilePanelContext";
import ObjectContext from "../contexts/ObjectContext";
import { PileType } from "../../types/pile.types";
import { ThreeEvent } from "react-three-fiber";

const unselectedColor = grey[200];

const PileGeomtry = ({ ...props }) => {
  const ref = useRef<any>();
  const index = props.index;
  const pileDiv = props.pileDiv;
  const id = props.id;
  const scale = props.scale;
  const scaleX = scale[0];
  const scaleZ = scale[2] !== 0 ? scale[2] : 1;
  const pileSelect = props.pileSelect;
  const annotation = props.annotation;
  const userData: PileType = props.userData;
  const pileColor = props.color;
  const pinScale = 1.7;
  const [sc, setsc] = useState(false);
  const color = new THREE.Color();
  var count = 0;
  const { isStashed } = props;
  useFrame((state) => {
    count += 1;
    ref.current.color.lerp(color.set(pileColor), 0.1);
    ref.current.children[0].material.map.needsUpdate =
      count % pileDiv === index % pileDiv;
  });

  var clickCount = 0;
  const handleOnSingleOrDoubleClick = (e: ThreeEvent<MouseEvent>) => {
    if (e.object.type === "Group" || e.object.name === "pin") {
      e.stopPropagation();
      clickCount++;
      if (clickCount < 2) {
        setTimeout(() => {
          if (clickCount > 1) {
            // double click
            setsc(!sc);
          } else {
            // single click
            props.changeSelected(userData);
            // ↓選択した杭にフォーカスするアニメーションをつける場合はここを使う
            // props.zoomToView(origin.clone());
            // props.startAnimate();
          }
          clickCount = 0;
        }, 200);
      }
    }
  };

  return (
    <Instance
      key={"pileInstance" + String(id)}
      ref={ref}
      position={props.position}
      scale={scale}
      userData={props.userData}
      onClick={(e:ThreeEvent<MouseEvent>) => {
        handleOnSingleOrDoubleClick(e);
      }}
    >
      <Pin
        position={[0, 0, (props.selected_point.z - props.position[2]) / scaleZ]}
        // undefinedの時もfalseを返す
        scale={[pinScale / scaleX, pinScale / scaleX, pinScale / scaleX]}
        fillColor={
          pileSelect.id === id
            ? "green"
            : `#${darkenColor(pileColor).getHexString()}`
        }
        circleColor={props.isPileDateValid ? "green" : "white"}
        isStashed={isStashed}
      >
        {props.id}
      </Pin>
      {sc ? (
        <Annotation
          opacity={1}
          position={[
            0,
            0,
            (props.annotation_point.z - props.position[2]) / scaleZ,
          ]}
          align="left"
          yOffset={120}
          scale={[20 / scaleX, 12 / scaleX, 12 / scaleX]}
        >
          {annotation}
        </Annotation>
      ) : null}
    </Instance>
  );
};

type PileInstanceProps = {
  focus: THREE.Vector3;
  setFocus: (focus: THREE.Vector3) => void;
  animate: boolean;
  setAnimate: (animate: boolean) => void;
};

const PileInstance = (props: PileInstanceProps) => {
  // Context
  const { setPilePanelProvided } = useContext(PilePanelContext);
  const { objectProvided, setObjectProvided } = useContext(ObjectContext);

  let pile_color;
  // let extraLength = 0;

  const latestDate = getLatestDate(objectProvided.piles);

  const setFocus = props.setFocus;
  const setAnimate = props.setAnimate;
  const zoomToView = (origin: any) => {
    setFocus(origin);
  };
  const startAnimate = () => {
    setAnimate(true);
  };

  // 選択
  const changeSelected = (userData: PileType) => {
    setObjectProvided({
      type: "setSelectedPile",
      payload: { id: userData.index },
    });
    setPilePanelProvided(false);
  };
  const pileDiv = Math.ceil(objectProvided.piles.length / 30);
  // work areaが"selectedWorkArea"の杭のみ
  const pilesToRender =
    objectProvided.selectedWorkArea.key === "all"
      ? objectProvided.piles
      : objectProvided.piles.filter((pile) => {
          return pile.props.work_area === objectProvided.selectedWorkArea.name;
        });
  const pileAttributes = pilesToRender.map((pile, index) => {
    const point = pile.point;
    const diameter = pile.diameter;
    const length = pile.length;
    const origin = pile.origin;
    const filterIndex = objectProvided.pileFilter.visibleStatus.map(
      (filter) => {
        const index = names.indexOf(filter);
        return index;
      }
    );
    // 杭の余長ごとの色
    pile_color = colors[pile.extraLengthIndex];
    // 選択されている範囲に含まれているか
    const isIncluded = filterIndex.includes(pile.extraLengthIndex);

    const annotation = getPileAnnotation(pile);
    const annotation_point = new THREE.Vector3(
      pile.origin.x,
      pile.origin.y,
      pile.origin.z + 0.3
    );
    const selected_point = new THREE.Vector3(
      pile.origin.x,
      pile.origin.y,
      pile.origin.z + 0.8
    );
    // 施工日のアノテーション
    const date_of_work = dateToStrforAnnotation(pile.props.date_of_work);
    const isPileDateValid = !isInvalidDate(pile.props.date_of_work);
    const data_point = new THREE.Vector3(
      pile.origin.x,
      pile.origin.y,
      pile.origin.z + 1.2
    );

    // 施工日が入力されているかで色を変更
    pile_color = changePileColorByDate(pile_color, isPileDateValid);
    // 選択されていなければ色を変更
    pile_color = isIncluded ? pile_color : unselectedColor;
    // IsStashedの場合はtext_colorを変更
    const isStashed  = objectProvided.stashedPile?.props.index === pile.props.index;

    return {
      index: index,
      pileDiv: pileDiv,
      id: pile.props.index,
      origin: origin,
      position: [point.x, point.y, point.z],
      scale: [diameter / 2, diameter / 2, length],
      color: pile_color,
      userData: pile.props,
      changeSelected: changeSelected,
      zoomToView: zoomToView,
      startAnimate: startAnimate,
      pileSelect: objectProvided.selectedPile,
      annotation: annotation,
      annotation_point: annotation_point,
      selected_point: selected_point,
      date_of_work: pile.props.date_of_work,
      date_of_work_text: date_of_work,
      isPileDateValid: isPileDateValid,
      data_point: data_point,
      latestDate: latestDate,
      isStashed,
    };
  });

  const pileMaterial = new THREE.MeshPhysicalMaterial({
    color: unselectedColor,
    wireframe: false,
    side: THREE.DoubleSide,
    transparent: true,
    opacity: 0.9,
  });
  const cylinderGeom = new THREE.CylinderGeometry(1, 1, 1, 10);
  cylinderGeom.rotateX(Math.PI / 2);

  return (
    <>
      <Instances material={pileMaterial} geometry={cylinderGeom} limit={10000}>
        {pileAttributes.map((props, i) => (
          <>
            <PileGeomtry key={i} {...props} />
          </>
        ))}
      </Instances>
    </>
  );
};

export default PileInstance;
