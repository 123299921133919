import React, { useState, useEffect, useContext, SyntheticEvent } from "react";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";
// Ampify
import { API } from "aws-amplify";
import {
  listProjects,
  getProjectById,
  listPileSetsByProjectId,
  getMeshByProjectId,
  getPilesByPileSetId,
} from "../../graphql/queries";
// Contexts
import ProjectContext from "../contexts/ProjectContext";
import ProgressContext from "../contexts/ProgressContext";
import ObjectContext from "../contexts/ObjectContext";
// Utilities
import { createPileGeometry } from "../utils/objectUtils";
import { createMeshPoints } from "../utils/meshUtils";
import { PileInputToPile } from "../utils/pileUtils";
import { fileListErrorAlert } from "../utils/alertUtils";
import { replaceNullWithNaN } from "../utils/csvUtils";
// Types
import { Project } from "../../types/project.types";
import { PileCSVType, PileDataType, PileInputType } from "../../types/pile.types";

const ProjectOpen = () => {
  // State
  const [selectedValue, setSelectedValue] = useState<any>({
    id: "",
    name: "",
  });
  // Context
  const { projectProvided, setProjectProvided } = useContext(ProjectContext);
  const { setProgressProvided } = useContext(ProgressContext);
  const { setObjectProvided } = useContext(ObjectContext);
  // Autocomplete
  const [open, setOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<Project[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        const projects = (await API.graphql({
          query: listProjects,
          authMode: "AMAZON_COGNITO_USER_POOLS",
        })) as any;
        if (projects.data.listProjects.length > 0) {
          setOptions([...projects.data.listProjects]);
          if (projects.data.listProjects.length === 1) {
            setSelectedValue(projects.data.listProjects[0]);
            await handleChange(null, projects.data.listProjects[0]);
          }
        }
      } catch (error) {
        fileListErrorAlert();
      }
    })();
  }, []);

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      try {
        const projects = (await API.graphql({
          query: listProjects,
          authMode: "AMAZON_COGNITO_USER_POOLS",
        })) as any;
        if (active) {
          if (projects.data.listProjects.length > 0) {
            setOptions([...projects.data.listProjects]);
          }
          setLoading(false);
        }
      } catch (error) {
        fileListErrorAlert();
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  // プロジェクトが変更されたときに、Autocompleteの値を変更する
  useEffect(() => {
    if (projectProvided.project !== undefined) {
      setObjectProvided({
        type: "setStashedPile",
        payload: undefined,
      });
      setSelectedValue(projectProvided.project);
    }
  }, [projectProvided.project]);

  const handleChange = async (
    event: SyntheticEvent<Element, Event> | null,
    value: any,
  ) => {
    const newValue = value;
    console.log(newValue);
    setProgressProvided(true);
    if (newValue !== null) {
      // get Project data from DB
      const projectRes = (await API.graphql({
        query: getProjectById,
        variables: { projectId: newValue.id },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      })) as any;
      const pileSets = (await API.graphql({
        query: listPileSetsByProjectId,
        variables: { projectId: newValue.id },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      })) as any;
      if (pileSets.data.listPileSetsByProjectId === null) {
        return;
      }
      // get Mesh data from DB
      console.log(newValue.id);
      const meshRes = (await API.graphql({
        query: getMeshByProjectId,
        variables: { projectId: newValue.id },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      })) as any;
      // get Pile data from DB
      const pileSet = pileSets.data.listPileSetsByProjectId[0];
      const pilesRes = (await API.graphql({
        query: getPilesByPileSetId,
        variables: {
          projectId: newValue.id,
          pileSetId: pileSet.id,
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      })) as any;
      if (pilesRes.data.getPilesByPileSetId === null) {
        return;
      }
      // update Project Context
      setProjectProvided({
        ...projectProvided,
        project: newValue,
        pileFiles: pileSets.data.listPileSetsByProjectId,
        isPileNewest: true,
        selectedPile: pileSets.data.listPileSetsByProjectId[0],
      });
      // update ProjectSettings Context
      const projectSettings = projectRes.data.getProjectById.settings;
      // create mesh points
      const initialMeshPoints =
        meshRes.data.getMeshByProjectId !== null
          ? createMeshPoints(meshRes.data.getMeshByProjectId.points)
          : [];
      // create pile object
      const pilesObjectArray = pilesRes.data.getPilesByPileSetId.map((pile: PileCSVType) => {
        return replaceNullWithNaN(pile) as PileInputType;
      });
      const piles = PileInputToPile(pilesObjectArray);
      console.log(piles.length);
      // create pile object
      const pilePoints = piles.map((pile) => {
        return { props: pile } as PileDataType;
      });
      const {pileObjectArray: pileObjectArrayWithInfo, pointsAddToMesh} =
        createPileGeometry(pilePoints);
      // set marginOptionProvided
      setObjectProvided({
        type: "openProject",
        payload: {
          pileMarginOption: {
            method: projectSettings.pileIndexParam,
            param1: projectSettings.pileParamA,
            param2: projectSettings.pileParamB,
            param3: projectSettings.pileParamC,
            param4: projectSettings.pileParamD,
          },
          pileFilter: {
            range: [
              projectSettings.extraLengthParamA,
              projectSettings.extraLengthParamB,
              projectSettings.extraLengthParamC,
            ],
            visibleStatus: projectSettings.extraLengthVisible,
          },
          meshColor: {
            colorMap: projectSettings.meshColor,
            method: projectSettings.meshIndexParam,
          },
          mesh: {
            initialPoints: initialMeshPoints,
            pointsAddToMesh: pointsAddToMesh,
          },
          piles: pileObjectArrayWithInfo as PileDataType[],
        },
      });
    }
    setProgressProvided(false);
  };

  return (
    <>
      <Autocomplete
        id="autocomplete-project-list"
        disableClearable
        open={open}
        value={selectedValue}
        noOptionsText="プロジェクトがありません"
        onOpen={() => {
          setOpen(true);
          setLoading(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name}
        options={options}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            label="ファイル選択"
            variant="standard"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        onChange={handleChange}
      />
    </>
  );
};

export default ProjectOpen;
