import { SetStateAction, useState } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Menu, MenuItem, Divider, ListItemIcon, IconButton } from "@mui/material";
import { AccountCircle, Logout } from "@mui/icons-material";

const UserMenu = () => {
    const { user, signOut } = useAuthenticator((context) => [
        context.user,
        context.route,
        context.signOut,
      ]);

  // User Menu
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenu = (event: { currentTarget: SetStateAction<null>; }) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
    <IconButton
      size="large"
      aria-label="account of current user"
      aria-controls="menu-appbar"
      aria-haspopup="true"
      onClick={handleMenu}
      color="inherit"
    >
      <AccountCircle />
    </IconButton>
    <Menu
      id="menu-appbar"
      anchorEl={anchorEl}
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <MenuItem>ユーザー: {user.username}</MenuItem>
      <Divider />
      <MenuItem onClick={signOut}>
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        サインアウト
      </MenuItem>
    </Menu>
  </div>
  )
}

export default UserMenu