import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Stack,
} from "@mui/material";
// Config
import { csvHeaderToJpHeader } from "../config/CsvConfig";
// Type
import { PileSetDifferences } from "../../types/pile.types";

type Result = "forceCreate" | "stash" | "merge" | "cancel";

type ConfirmDialogProps = {
  open: boolean;
  onClose: (result: Result) => void;
  title: string;
  message: string;
  differences: PileSetDifferences | null;
};

const CHECK_DIFFERENCE_DIALOG = ({
  open,
  onClose,
  title,
  message,
  differences,
}: ConfirmDialogProps) => (
  <Dialog open={open} onClose={() => onClose("cancel")}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{message}</DialogContentText>
      {differences && (
        <>
          <DialogContentText component="div">
            <ul>
              {differences.differences.map((difference, index) => (
                <React.Fragment key={`fragment_${index}`}>
                  <li key={`li_${index}`}>杭番号: {difference.pileIndex}</li>
                  {/* <ul key={`ul_${index}`}>
                    {difference.differentFields.map((field, index) => (
                      <li key={index}>{csvHeaderToJpHeader(field)}</li>
                    ))}
                  </ul> */}
                </React.Fragment>
              ))}
            </ul>
          </DialogContentText>
          <DialogContentText>
          対応を選択してください。
          </DialogContentText>
        </>
      )}
    </DialogContent>
    <DialogActions>
      <Stack spacing={1} width="100%" direction="column">
        <Button
          onClick={() => onClose("merge")}
          variant="contained"
          color="primary"
        >
          統合して保存する
        </Button>
        {/* <Button
          onClick={() => onClose("stash")}
          variant="outlined"
          color="secondary"
        >
          変更内容を一時保存して最新杭リストを読み込む
        </Button> */}
        {/* <Button
          onClick={() => onClose("forceCreate")}
          variant="outlined"
          color="error"
          autoFocus
        >
          現在の杭リストを最新として保存
        </Button> */}
        <Button onClick={() => onClose("stash")} variant="outlined">
          戻って杭情報を確認する
        </Button>
      </Stack>
    </DialogActions>
  </Dialog>
);

const useCheckDifferenceDialog = () => {
  const [open, setOpen] = useState(false);
  const [differences, setDifferences] = useState<PileSetDifferences | null>(
    null
  );
  const [resolve, setResolve] = useState<
    ((value: Result | PromiseLike<Result>) => void) | null
  >(null);

  const openCheckDifferenceDialog = (
    pileSetDifferences: PileSetDifferences
  ) => {
    setDifferences(pileSetDifferences);
    setOpen(true);
    return new Promise<Result>((res) => {
      setResolve(() => res);
    });
  };

  const onClose = (result: Result) => {
    setOpen(false);
    if (resolve) {
      resolve(result);
    }
  };

  const CheckDifferenceDialog = (
    props: Omit<ConfirmDialogProps, "open" | "onClose" | "differences">
  ) => (
    <CHECK_DIFFERENCE_DIALOG
      open={open}
      onClose={onClose}
      title={props.title}
      message={props.message}
      differences={differences}
    />
  );

  return {
    CheckDifferenceDialog,
    openCheckDifferenceDialog,
  };
};

export default useCheckDifferenceDialog;
