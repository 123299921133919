import {
  Raycaster,
  Vector3,
  MeshBasicMaterial,
  Mesh,
  DoubleSide,
  BufferGeometry,
  NormalBufferAttributes,
} from "three";

const getIntersection = (
  point: Vector3,
  meshGeometry: BufferGeometry<NormalBufferAttributes>
) => {
  var geometry = meshGeometry;
  var material = new MeshBasicMaterial({ side: DoubleSide });
  var testObject_Mesh = new Mesh(geometry, material);
  var direction = new Vector3(0, 0, 1);
  direction.normalize();
  var raycaster = new Raycaster(point, direction);
  var rayIntersects = raycaster.intersectObject(testObject_Mesh);
  if (rayIntersects[0]) {
    return rayIntersects[0].point;
  } else {
    var Idirection = new Vector3(0, 0, -1);
    Idirection.normalize();
    var Iraycaster = new Raycaster(point, Idirection);
    var IrayIntersects = Iraycaster.intersectObject(testObject_Mesh);
    // console.log(IrayIntersects)
    if (IrayIntersects[0]) {
      return IrayIntersects[0].point;
    } else {
      return null;
    }
  }
};

export default getIntersection;
