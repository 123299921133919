// Amplify
import {
  useTheme,
  View,
} from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify";
import { translations } from "@aws-amplify/ui-react";

// Mui
import { Box } from "@mui/material";

// Translations
I18n.putVocabularies(translations);
I18n.setLanguage("ja");

const formFields = {};

const components = {
  Header() {
    const { tokens } = useTheme();
    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Box
          component="img"
          sx={{
            height: 80,
            width: 80,
          }}
          alt="ANAGO_logo"
          src="/icon.png"
        />
      </View>
    );
  },
};

export { formFields, components };
