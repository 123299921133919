import { useState, useContext, SetStateAction } from "react";
import {
  Stack,
  Typography,
  Button,
  DialogContent,
  DialogActions,
} from "@mui/material";
// Ampify
import { API } from "aws-amplify";
import { updateMesh } from "../../graphql/mutations";
import { getMeshByProjectId } from "../../graphql/queries";
// Contexts
import UploadStatusContext from "../contexts/UploadStatusContext";
import ProgressContext from "../contexts/ProgressContext";
import ProjectContext from "../contexts/ProjectContext";
import ObjectContext from "../contexts/ObjectContext";

// Components
import NewProjectFileUpload from "./NewProjectFileUpload";
// Utilities
import { createMeshPoints } from "../utils/meshUtils";
import { createPileGeometry } from "../utils/objectUtils";
import {
  fileUploadErrorAlert,
  fileValidationErrorAlert,
} from "../utils/alertUtils";
import { csvFileToObjectArray } from "../utils/csvUtils";

type UpdateMeshProps = {
  handleClick: (action: string) => void;
};

const UpdateMesh = (props: UpdateMeshProps) => {
  // Contexts
  const { setUploadStatusProvided } = useContext(UploadStatusContext);
  const { setProgressProvided } = useContext(ProgressContext);
  const { projectProvided, setProjectProvided } = useContext(ProjectContext);
  const { objectProvided, setObjectProvided } = useContext(ObjectContext);
  // Props
  const { handleClick } = props;
  // Validation
  const [inputError, setInputError] = useState({
    meshFile: true,
  });

  // Mesh File
  const [meshFile, setMeshFile] = useState(null);
  const handleMeshFileChange = (e: { target: { files: SetStateAction<null>[]; }; }) => {
    if (e.target.files[0] === undefined) {
      setMeshFile(null);
      setInputError({ ...inputError, meshFile: true });
      return;
    }
    setMeshFile(e.target.files[0]);
    setInputError({ ...inputError, meshFile: false });
  };

  // 作成ボタンを押したときの処理
  const updateNewMesh = async () => {
    if (meshFile) {
      // convert mesh and pile csv file to object array
      const meshObjectArray = await (async () => {
        try {
          return await csvFileToObjectArray(meshFile, "mesh");
        } catch (e) {
          fileValidationErrorAlert("mesh");
          throw new Error("mesh file validation error");
        }
      })();
      const meshObjectArrayWithType = meshObjectArray.map((mesh) => {
        return {
          x: parseFloat(mesh.x),
          y: parseFloat(mesh.y),
          z: parseFloat(mesh.z),
        };
      });
      try {
        const project = projectProvided.project;
        // post mesh and pile data
        const updateMeshRes = await API.graphql({
          query: updateMesh,
          variables: {
            projectId: project.id,
            meshInput: { points: meshObjectArrayWithType },
          },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        }) as any;
        if (!updateMeshRes.data.updateMesh) {
          fileUploadErrorAlert();
          throw new Error("mesh file validation error");
        }
        const meshRes = await API.graphql({
          query: getMeshByProjectId,
          variables: { projectId: project.id },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        }) as any;
        const initialMeshPoints =
          meshRes.data.getMeshByProjectId !== null
            ? createMeshPoints(meshRes.data.getMeshByProjectId.points)
            : [];
        const { pileObjectArray: pileObjectArrayWithInfo, pointsAddToMesh } =
          createPileGeometry(objectProvided.piles);
        // set marginOptionProvided
        setObjectProvided({
          type: "setMesh",
          payload: {
            type: "update",
            mesh: {
              initialPoints: initialMeshPoints,
              pointsAddToMesh,
            },
            piles: pileObjectArrayWithInfo,
          },
        });
        // close dialog
        setUploadStatusProvided(true);
        handleClick("close");
      } catch (e) {
        console.log(e);
        fileUploadErrorAlert();
      }
    }
  };

  return (
    <>
      <DialogContent>
        <Stack spacing={1.0}>
          <Typography variant="subtitle1">新しい地盤データ</Typography>
          <NewProjectFileUpload
            file={meshFile}
            handleChange={handleMeshFileChange}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          disabled={Object.values(inputError).some((item) => item)}
          onClick={async () => {
            setProgressProvided(true);
            try {
              await updateNewMesh();
            } catch (e) {
              console.log(e);
            }
            setProgressProvided(false);
          }}
        >
          アップロード
        </Button>
      </DialogActions>
    </>
  );
};

export default UpdateMesh;
