import { createContext, useReducer, FC, ReactNode } from "react";
// Types
type Props = { children: ReactNode };
type UploadStatusContextType = boolean;
type ActionType = boolean;
type UploadStatusContextValue = {
  uploadStatusProvided: UploadStatusContextType;
  setUploadStatusProvided: React.Dispatch<ActionType>;
};

const UploadStatusContext = createContext<UploadStatusContextValue>({
  uploadStatusProvided: false as UploadStatusContextType,
  setUploadStatusProvided: () => {},
});

const initialUploadStatus = false;
const UploadStatusReducer = (
  initialUploadStatus: boolean,
  action: ActionType
): UploadStatusContextType => {
  return action;
};

const UploadStatusProvider: FC<Props> = ({ children }) => {
  const [uploadStatus, setUploadStatus] = useReducer(
    UploadStatusReducer,
    initialUploadStatus
  );
  return (
    <UploadStatusContext.Provider
      value={{
        uploadStatusProvided: uploadStatus,
        setUploadStatusProvided: setUploadStatus,
      }}
    >
      {children}
    </UploadStatusContext.Provider>
  );
};

export default UploadStatusContext;
export { UploadStatusProvider, initialUploadStatus };
