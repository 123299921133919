import React, { useContext } from "react";
// mui
import { Snackbar, Alert, ThemeProvider, SnackbarCloseReason } from "@mui/material";
// Contexts
import UploadStatusContext from "../contexts/UploadStatusContext";
// Style
import { darkTheme } from "../darkTheme";

// UploadStatusContextの型定義
interface UploadStatusContextType {
  uploadStatusProvided: boolean;
  setUploadStatusProvided: React.Dispatch<React.SetStateAction<boolean>>;
}

const UploadSnackBar: React.FC = () => {
  const { uploadStatusProvided, setUploadStatusProvided } =
    useContext(UploadStatusContext as React.Context<UploadStatusContextType>);

  const snackBarHandleClose = (event: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === "clickaway") {
      return;
    }

    setUploadStatusProvided(false);
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Snackbar
        open={uploadStatusProvided}
        autoHideDuration={6000}
        onClose={snackBarHandleClose}
      >
        <Alert
          onClose={snackBarHandleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          ファイルはクラウドにアップロードされました。
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};

export default UploadSnackBar;