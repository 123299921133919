import * as THREE from "three";
import { Lut } from "three/examples/jsm/math/Lut";
import { Color } from "three";
import { MeshColorType, MeshGeometryType } from "../../types/mesh.types";

const lut = new Lut();

lut.addColorMap("bluttowhite", [
  // [0.0, new Color("rgb(35, 70, 77)").getHex()],
  // [0.4, new Color("rgb(3, 20, 64)").getHex()],
  // [0.7, new Color("rgb(67, 128, 240)").getHex()],
  // [0.9, new Color("rgb(143, 206, 255)").getHex()],
  // [1.0, new Color("rgb(255, 255, 255)").getHex()],
  [0.0, new Color("rgb(3, 20, 64)").getHex()],
  [0.4, new Color("rgb(67, 128, 240)").getHex()],
  [0.7, new Color("rgb(143, 206, 255)").getHex()],
  [1.0, new Color("rgb(255, 255, 255)").getHex()],
]);

lut.addColorMap("reverse_rainbow", [
  [0.0, 0xff0000],
  [0.2, 0xffff00],
  [0.5, 0x00ff00],
  [0.8, 0x00ffff],
  [1.0, 0x0000ff],
]);

lut.addColorMap("warmtocool", [
  [0.0, 0xB40426],
  [0.2, 0xF6A385],
  [0.5, 0xDCDCDC],
  [0.8, 0x9BBCFF],
  [1.0, 0x3C4EC2],
]);

lut.setMax(1);
lut.setMin(0);

const initialMeshColor: MeshColorType = {
  // カラーマップ
  colorMap: 1,
  // 計算方法
  method: 1,
  lut: lut,
};

const initialMeshGeometry: MeshGeometryType = {
  geometry: new THREE.BufferGeometry(),
  initialPoints: [],
  points: [],
  distance: [],
  box: new THREE.Box3(),
};

export { lut, initialMeshColor, initialMeshGeometry };
