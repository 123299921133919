import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";

type Result = "confirm" | "cancel";

type ConfirmDialogProps = {
  open: boolean;
  onClose: (result: Result) => void;
  title: string;
  message: string;
};

const CONFIRM_DIALOG = ({ open, onClose, title, message }: ConfirmDialogProps) => (
  <Dialog open={open} onClose={() => onClose("cancel")}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{message}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => onClose("cancel")} variant="outlined">
        キャンセル
      </Button>
      <Button onClick={() => onClose("confirm")} variant="contained" color="error" autoFocus>
        クリア
      </Button>
    </DialogActions>
  </Dialog>
);

const useConfirmDialog = () => {
  const [open, setOpen] = useState(false);
  const [resolve, setResolve] = useState<((value: Result | PromiseLike<Result>) => void) | null>(null);

  const openConfirmDialog = () => {
    setOpen(true);
    return new Promise<Result>((res) => {
      setResolve(() => res);
    });
  };

  const onClose = (result: Result) => {
    setOpen(false);
    if (resolve) {
      resolve(result);
    }
  };

  const ConfirmDialog = (props: Omit<ConfirmDialogProps, 'open' | 'onClose'>) => (
    <CONFIRM_DIALOG open={open} onClose={onClose} title={props.title} message={props.message} />
  );

  return {
    ConfirmDialog,
    openConfirmDialog,
  };
};

export default useConfirmDialog;