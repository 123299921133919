type PileValueConfigType = {
    title: string;
    design: string;
    plan: string;
    actual: string;
    isActualTextField: boolean;
    required: boolean;
};

const PileValueConfig: { [key: string]: PileValueConfigType } = {
    top_level: {
        title: "杭天レベル",
        design: "top_level_design",
        plan: "top_level_plan",
        actual: "top_level_actual",
        isActualTextField: true,
        required: false,
    },
    pile_length: {
        title: "杭長",
        design: "pile_length_design",
        plan: "pile_length_plan",
        actual: "pile_length_actual",
        isActualTextField: true,
        required: false,
    },
    support_depth: {
        title: "支持層深さ",
        design: "soil_depth_design",
        plan: "soil_depth_plan",
        actual: "soil_depth_actual",
        isActualTextField: true,
        required: true,
    },
    extra_length: {
        title: "根入れ余長",
        design: "extra_embedment_design",
        plan: "extra_embedment_plan",
        actual: "extra_embedment_actual",
        isActualTextField: false,
        required: false,
    }
};

export { PileValueConfig };