import React, { useContext, useRef, useState, useEffect } from "react";
import {
  Card,
  TextField,
  CardContent,
  Button,
  Typography,
  ThemeProvider,
  Stack,
  IconButton,
  Divider,
  Collapse,
  ListItemButton,
  ListItemText,
  Box,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { LocalizationProvider, DesktopDatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { styled } from "@mui/material/styles";
import { darkTheme } from "./darkTheme";
import ja from "date-fns/locale/ja";

// Contexts
import PilePanelContext from "./contexts/PilePanelContext";
import ProjectContext from "./contexts/ProjectContext";
import UploadStatusContext from "./contexts/UploadStatusContext";
import ProgressContext from "./contexts/ProgressContext";
import ObjectContext from "./contexts/ObjectContext";
// Items
import PileValue from "./items/PileValue";
import useConfirmDialog from "./items/useConfirmDialog";
import useCheckDifferenceDialog from "./items/useCheckDifferenceDialog";
// Config
import { PileValueConfig } from "./config/PileValueConfig";
// Utilities
import {
  adjustNumberForRightPanel,
  getPilePointsAddToMesh,
  PileInputToPile,
} from "./utils/pileUtils";
import { fileUploadErrorAlert } from "./utils/alertUtils";
import { PileToPileInput } from "./utils/pileUtils";
import { replaceNullWithNaN } from "./utils/csvUtils";
import { createPileGeometry } from "./utils/objectUtils";
// Type
import { PileCSVType, PileInputType, PileSetDifferences } from "../types/pile.types";
import { PileDataType } from "../types/pile.types";
// Amplify
import { API, Auth } from "aws-amplify";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { createPileSet } from "../graphql/mutations";
import {
  listPileSetsByProjectId,
  getPileSetDifferences,
  getPilesByPileSetId,
} from "../graphql/queries";
const minWidth = 250;
const maxWidth = 300;

const RightPanelContainer = styled("div")({
  width: "22%",
  minWidth: minWidth,
  maxWidth: maxWidth,
  height: "55vh",
  position: "absolute",
  float: "right",
  zIndex: "1",
  // margin: "10px",
  top: 10 + 64,
  right: 10,
  // boxSizing: 'border-box',
});

const styles = {
  paperprops: {
    "div[role=presentation]": {
      display: "flex",
      "& .PrivatePickersFadeTransitionGroup-root:first-of-type": {
        order: 2,
      },
      "& .PrivatePickersFadeTransitionGroup-root:nth-of-type(2)": {
        order: 1,
        "& div::after": {
          content: '"年"',
        },
      },
      "& .MuiButtonBase-root": {
        order: 3,
      },
    },
  },
  mobiledialogprops: {
    ".PrivatePickersToolbar-dateTitleContainer .MuiTypography-root": {
      fontSize: "1.5rem", // 選択した日付のフォントサイズを変更
    },
    "div[role=presentation]:first-of-type": {
      display: "flex",
      "& .PrivatePickersFadeTransitionGroup-root:first-of-type": {
        order: 2,
      },
      "& .PrivatePickersFadeTransitionGroup-root:nth-of-type(2)": {
        order: 1,
        "& > div::after": {
          content: '"年"',
        },
      },

      "& .MuiButtonBase-root": {
        order: 3,
      },
    },
  },
};

const getActualValue = (data: number) => {
  return isNaN(data) ? data : adjustNumberForRightPanel(data);
};

const RightPanel = () => {
  // Contexts
  const { pilePanelProvided, setPilePanelProvided } =
    useContext(PilePanelContext);
  const { objectProvided, setObjectProvided } = useContext(ObjectContext);
  const { projectProvided, setProjectProvided } = useContext(ProjectContext);
  const { setUploadStatusProvided } = useContext(UploadStatusContext);
  const { setProgressProvided } = useContext(ProgressContext);
  // State
  const [pileData, setPileData] = useState<PileDataType>({
    props: {},
  } as PileDataType);
  const [panelOpen, setPanelOpen] = useState(true);
  const [difference, setDifference] = useState<boolean>(true);
  // Ref
  const dateRef = useRef<HTMLInputElement | null>(null);
  const handleDateFieldChange = () => {
    if (dateRef.current !== null) {
      dateRef.current.value === ""
        ? setInputError({ ...inputError, date: true })
        : setInputError({ ...inputError, date: false });
    }
  };
  // Auth
  const { user } = useAuthenticator((context) => [context.user]);
  const [isEditableUser, setIsEditableUser] = useState(false);
  // Validation
  const [inputError, setInputError] = useState({
    date: true,
    soil_depth_actual: true,
  });
  // ConfirmDialog
  const { ConfirmDialog, openConfirmDialog } = useConfirmDialog();
  // CheckDifferenceDialog
  const { CheckDifferenceDialog, openCheckDifferenceDialog } =
    useCheckDifferenceDialog();
  // Effect
  useEffect(() => {
    setInputError((prevInputError) => ({
      ...prevInputError,
      date: pileData.props.date_of_work ? false : true,
      soil_depth_actual: isNaN(pileData.props.soil_depth_actual),
    }));
  }, [pileData, setInputError]);

  useEffect(() => {
    Auth.currentSession().then((user) => {
      const { payload } = user.getIdToken();
      if (payload && payload["cognito:groups"]) {
        setIsEditableUser(
          payload["cognito:groups"].includes(
            "User_" + projectProvided.project.id
          ) || payload["cognito:groups"].includes("Admin")
        );
      }
    });
  }, [user, projectProvided]);

  useEffect(() => {
    if (objectProvided.selectedPile.id) {
      const selectedPile = objectProvided.piles.find(
        (item) => item.props.index === objectProvided.selectedPile.id
      );
      if (selectedPile) {
        setPileData(selectedPile);
      }
    }
  }, [objectProvided]);
  // Functions
  const updateObjects = (reset = false) => {
    const newPileProp = [...objectProvided.piles];
    console.log(pileData);
    console.log(newPileProp);
    const selectedPile = newPileProp.find(
      (item) => item.props.index === pileData.props.index
    );
    if (!selectedPile) {
      return;
    }
    if (reset) {
      selectedPile.props.top_level_actual = NaN;
      selectedPile.props.pile_length_actual = NaN;
      selectedPile.props.soil_depth_actual = NaN;
      selectedPile.props.extra_embedment_actual = NaN;
      selectedPile.props.date_of_work = new Date("");
      selectedPile.props.description = "";
    } else {
      selectedPile.props.top_level_actual = Number(
        getActualValue(pileData.props.top_level_actual)
      );
      selectedPile.props.pile_length_actual = Number(
        getActualValue(pileData.props.pile_length_actual)
      );
      selectedPile.props.soil_depth_actual = Number(
        getActualValue(pileData.props.soil_depth_actual)
      );
      selectedPile.props.extra_embedment_actual = Number(
        getActualValue(pileData.props.extra_embedment_actual)
      );
      selectedPile.props.date_of_work = pileData.props.date_of_work;
      selectedPile.props.description = pileData.props.description;
    }
    // meshに追加するPointsを取得
    const pointsAddToMesh = getPilePointsAddToMesh(newPileProp);
    setObjectProvided({
      type: "updatePileSet",
      payload: {
        mesh: {
          pointsAddToMesh: pointsAddToMesh,
        },
        piles: newPileProp,
      },
    });
    setPileData(selectedPile);
  };

  const updateProject = async (reset = false) => {
    // update pile
    updateObjects(reset);
    // convert pile to pileInput
    const pileInput = PileToPileInput(objectProvided.piles);
    const project = projectProvided.project;
    try {
      // create pile set
      const pileSetRes = (await API.graphql({
        query: createPileSet,
        variables: { projectId: project.id, pilesInput: pileInput },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      })) as any;
      const pileSet = pileSetRes.data.createPileSet;
      const pileSets = (await API.graphql({
        query: listPileSetsByProjectId,
        variables: { projectId: project.id },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      })) as any;
      console.log(project.id);
      console.log(pileSet.id);
      setProjectProvided({
        ...projectProvided,
        project,
        isPileNewest: true,
        pileFiles: pileSets.data.listPileSetsByProjectId,
        selectedPile: pileSet,
      });
      // プロジェクト更新ステータス
      setUploadStatusProvided(true);
    } catch (e) {
      console.log(e);
      fileUploadErrorAlert();
    }

    // Panelを閉じる
    setPilePanelProvided(true);
  };

  const onUpdateCheck = async () => {
    setProgressProvided(true);
    const pileSetDifferences = await checkPileSetDifferences();
    if (pileSetDifferences.differences.length > 0) {
      setProgressProvided(false);
      setDifference(true);
      const result = await openCheckDifferenceDialog(pileSetDifferences);
      switch (result) {
        case "stash":
          setProgressProvided(true);
          await onStashPile();
          setProgressProvided(false);
          break;
        case "forceCreate":
          setProgressProvided(true);
          await updateProject();
          setProgressProvided(false);
          break;
        case "merge":
          setProgressProvided(true);
          await onMergePileSet();
          setProgressProvided(false);
          break;
        case "cancel":
          break;
        default:
          break;
      }
    } else {
      setDifference(false);
      await updateProject();
      setProgressProvided(false);
    }
  };

  const checkPileSetDifferences = async () => {
    const project = projectProvided.project;
    const pileSetResponse = (await API.graphql({
      query: listPileSetsByProjectId,
      variables: { projectId: project.id },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })) as any;
    const latestPileSet = pileSetResponse.data.listPileSetsByProjectId[0];
    const differenceResponse = (await API.graphql({
      query: getPileSetDifferences,
      variables: {
        projectId: project.id,
        pileSetIdA: latestPileSet.id,
        pileSetIdB: projectProvided.selectedPile.id,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })) as any;
    const pileSetDifferences = differenceResponse.data
      .getPileSetDifferences as PileSetDifferences;
    return pileSetDifferences;
  };

  const onStashPile = async () => {
    const project = projectProvided.project;
    const pileSets = (await API.graphql({
      query: listPileSetsByProjectId,
      variables: { projectId: project.id },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })) as any;
    setProjectProvided({
      ...projectProvided,
      project,
      isPileNewest: true,
      pileFiles: pileSets.data.listPileSetsByProjectId,
      selectedPile: pileSets.data.listPileSetsByProjectId[0],
    });
    const pilesRes = (await API.graphql({
      query: getPilesByPileSetId,
      variables: {
        projectId: projectProvided.project.id,
        pileSetId: pileSets.data.listPileSetsByProjectId[0].id,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })) as any;
    if (pilesRes.data.getPilesByPileSetId === null) {
      return;
    }
    const pilesObjectArray = pilesRes.data.getPilesByPileSetId.map(
      (pile: PileCSVType) => {
        return replaceNullWithNaN(pile) as PileInputType;
      }
    );
    const piles = PileInputToPile(pilesObjectArray);
    // create pile object
    const pilePoints = piles.map((pile) => {
      return { props: pile } as PileDataType;
    });
    const { pileObjectArray: pileObjectArrayWithInfo, pointsAddToMesh } =
      createPileGeometry(pilePoints);
    setObjectProvided({
      type: "openPileFile",
      payload: {
        mesh: {
          pointsAddToMesh,
        },
        piles: pileObjectArrayWithInfo,
      },
    });
    setObjectProvided({
      type: "setStashedPile",
      payload: pileData,
    });
  };

  const onMergePileSet = async () => {
    const project = projectProvided.project;
    const pileSets = (await API.graphql({
      query: listPileSetsByProjectId,
      variables: { projectId: project.id },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })) as any;
    setProjectProvided({
      ...projectProvided,
      project,
      isPileNewest: true,
      pileFiles: pileSets.data.listPileSetsByProjectId,
      selectedPile: pileSets.data.listPileSetsByProjectId[0],
    });
    const pilesRes = (await API.graphql({
      query: getPilesByPileSetId,
      variables: {
        projectId: projectProvided.project.id,
        pileSetId: pileSets.data.listPileSetsByProjectId[0].id,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })) as any;
    if (pilesRes.data.getPilesByPileSetId === null) {
      return;
    }
    const pilesObjectArray = pilesRes.data.getPilesByPileSetId.map(
      (pile: PileCSVType) => {
        return replaceNullWithNaN(pile) as PileInputType;
      }
    );
    const piles = PileInputToPile(pilesObjectArray);
    // create pile object
    const pilePoints = piles.map((pile) => {
      return { props: pile } as PileDataType;
    });
    const { pileObjectArray: newPileProp } = createPileGeometry(pilePoints);
    // update pile
    const selectedPile = newPileProp.find(
      (item) => item.props.index === pileData.props.index
    );
    if (!selectedPile) {
      return;
    }
    selectedPile.props.top_level_actual = Number(
      getActualValue(pileData.props.top_level_actual)
    );
    selectedPile.props.pile_length_actual = Number(
      getActualValue(pileData.props.pile_length_actual)
    );
    selectedPile.props.soil_depth_actual = Number(
      getActualValue(pileData.props.soil_depth_actual)
    );
    selectedPile.props.extra_embedment_actual = Number(
      getActualValue(pileData.props.extra_embedment_actual)
    );
    selectedPile.props.date_of_work = pileData.props.date_of_work;
    selectedPile.props.description = pileData.props.description;
    // meshに追加するPointsを取得
    const pointsAddToMesh = getPilePointsAddToMesh(newPileProp);
    setObjectProvided({
      type: "updatePileSet",
      payload: {
        mesh: {
          pointsAddToMesh: pointsAddToMesh,
        },
        piles: newPileProp,
      },
    });
    setPileData(selectedPile);
    // convert pile to pileInput
    const pileInput = PileToPileInput(newPileProp);
    try {
      // create pile set
      const pileSetRes = (await API.graphql({
        query: createPileSet,
        variables: { projectId: project.id, pilesInput: pileInput },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      })) as any;
      const pileSet = pileSetRes.data.createPileSet;
      const pileSets = (await API.graphql({
        query: listPileSetsByProjectId,
        variables: { projectId: project.id },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      })) as any;
      setProjectProvided({
        ...projectProvided,
        project,
        isPileNewest: true,
        pileFiles: pileSets.data.listPileSetsByProjectId,
        selectedPile: pileSet,
      });
      // プロジェクト更新ステータス
      setUploadStatusProvided(true);
    } catch (e) {
      console.log(e);
      fileUploadErrorAlert();
    }

    // Panelを閉じる
    setPilePanelProvided(true);
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <RightPanelContainer>
        <Card sx={{ minWidth: minWidth }} hidden={pilePanelProvided}>
          <IconButton
            aria-label="close"
            onClick={() => {
              // Panelを閉じる
              setPilePanelProvided(true);
              // select解除
              setObjectProvided({
                type: "setSelectedPile",
                payload: { id: undefined },
              });
              setInputError({
                ...inputError,
                date: true,
                soil_depth_actual: true,
              });
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme: any) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          {pileData.props.index && (
            <CardContent>
              <Stack spacing={0.4}>
                <Typography variant="subtitle1">杭情報</Typography>
                <ListItemButton
                  size="small"
                  onClick={() => setPanelOpen(!panelOpen)}
                >
                  <ListItemText
                    primary={
                      <Typography variant="subtitle1">
                        杭番号：{pileData.props.index}
                      </Typography>
                    }
                    id="pileInfo"
                  />
                  {panelOpen ? (
                    <ExpandLess id="pileInfo" />
                  ) : (
                    <ExpandMore id="pileInfo" />
                  )}
                </ListItemButton>
                <Divider />
                <Collapse in={panelOpen} timeout="auto" unmountOnExit>
                  <Grid container>
                    <Grid item xs={7}>
                      <Typography variant="body2">必要根入れ長さ：</Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography variant="body2" align="right">
                        {pileData.requiredExtraLength}mm
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                  <PileValue
                    data={pileData}
                    setData={setPileData}
                    config={PileValueConfig.extra_length}
                    inputError={inputError}
                    setInputError={setInputError}
                    disabled={!isEditableUser}
                  />
                  <Divider />
                  <PileValue
                    data={pileData}
                    setData={setPileData}
                    config={PileValueConfig.top_level}
                    inputError={inputError}
                    setInputError={setInputError}
                    disabled={!isEditableUser}
                  />
                  <Divider />
                  <PileValue
                    data={pileData}
                    setData={setPileData}
                    config={PileValueConfig.pile_length}
                    inputError={inputError}
                    setInputError={setInputError}
                    disabled={!isEditableUser}
                  />
                  <Divider />
                  <PileValue
                    data={pileData}
                    setData={setPileData}
                    config={PileValueConfig.support_depth}
                    inputError={inputError}
                    setInputError={setInputError}
                    disabled={!isEditableUser}
                  />
                  <Divider />
                  <Typography variant="subtitle1">打設日*</Typography>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={ja}
                  >
                    <DesktopDatePicker
                      inputFormat="yyyy年MM月dd日"
                      mask="____年__月__日"
                      leftArrowButtonText="前月を表示"
                      rightArrowButtonText="次月を表示"
                      toolbarTitle="日付選択"
                      disabled={!isEditableUser}
                      maxDate={new Date()}
                      PaperProps={{ sx: styles.paperprops }}
                      value={
                        isNaN(Number(pileData.props.date_of_work))
                          ? null
                          : pileData.props.date_of_work
                      }
                      onChange={(e: Date | null) => {
                        if (e === null) {
                          return;
                        }
                        setPileData({
                          ...pileData,
                          props: {
                            ...pileData.props,
                            date_of_work: e,
                          },
                        });
                        handleDateFieldChange();
                      }}
                      renderInput={(params) => (
                        <TextField
                          inputRef={dateRef}
                          size="small"
                          fullWidth
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <Divider />
                  <Typography variant="subtitle1">備考</Typography>
                  <TextField
                    id="outlined-multiline-flexible"
                    variant="outlined"
                    type="text"
                    size="small"
                    // multiline
                    // maxRows={1}
                    fullWidth
                    value={pileData.props.description}
                    disabled={!isEditableUser}
                    onChange={(e) => {
                      setPileData({
                        ...pileData,
                        props: {
                          ...pileData.props,
                          description: e.target.value,
                        },
                      });
                    }}
                  />
                  <Divider />
                  {isEditableUser && (
                    <div>
                      {/* <Box sx={{ my: 1 }}>
                        <Button
                          variant="contained"
                          color="success"
                          size="small"
                          onClick={onUpdateCheck}
                          fullWidth
                          // disabled={
                          //   !projectProvided.isPileNewest ||
                          //   Object.values(inputError).some((item) => item)
                          // }
                        >
                          {`保存(仮) ${difference}`}
                        </Button>
 
                      </Box> */}
                      <Box sx={{ my: 1 }}>
                        <Button
                          variant="contained"
                          size="small"
                          onClick={onUpdateCheck}
                          fullWidth
                          disabled={
                            !projectProvided.isPileNewest ||
                            Object.values(inputError).some((item) => item)
                          }
                        >
                          保存
                        </Button>
                        <CheckDifferenceDialog
                          title="⚠️確認"
                          message="他の端末から入力されたと思われる杭情報があります。"
                        />
                      </Box>
                      <Box sx={{ my: 1 }}>
                        <Button
                          variant="outlined"
                          color="error"
                          size="small"
                          onClick={async () => {
                            const result = await openConfirmDialog();
                            result === "confirm" &&
                              (async () => {
                                setProgressProvided(true);
                                await updateProject(true);
                                setProgressProvided(false);
                              })();
                          }}
                          fullWidth
                          disabled={
                            !projectProvided.isPileNewest ||
                            Object.values(inputError).some((item) => item)
                          }
                        >
                          クリア
                        </Button>
                        <ConfirmDialog
                          title="確認"
                          message="本当にクリアしますか？"
                        />
                      </Box>
                    </div>
                  )}
                </Collapse>
              </Stack>
            </CardContent>
          )}
        </Card>
      </RightPanelContainer>
    </ThemeProvider>
  );
};

export default RightPanel;
