import {
  Box,
  TextField,
  Typography,
  Grid,
  Stack,
} from "@mui/material";
import { useContext } from "react";
import { names, panelColors } from "../config/PileConfig";
// Contexts
import ProjectContext from "../contexts/ProjectContext";
import ObjectContext from "../contexts/ObjectContext";
// Utilities
import { fileUploadErrorAlert } from "../utils/alertUtils";
// Ampify
import { API } from "aws-amplify";
import { updateProjectSettings } from "../../graphql/mutations";

const colors = panelColors;

const FromTo = () => {
  return (
    <Typography variant="body2" align="center">
      ~
    </Typography>
  );
};
const ToValue = (props: { value: number; }) => {
  return (
    <Typography variant="body2" align="right">
      {props.value} mm
    </Typography>
  );
};

const PileFilter = () => {
  // Contexts
  const { projectProvided } = useContext(ProjectContext);
  const { objectProvided, setObjectProvided } = useContext(ObjectContext);
  // Change Pile File
  const handleChange = async (event:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const project = projectProvided.project;
    const newFilter = {
      ...objectProvided.pileFilter,
    };
    newFilter.range[Number(event.target.name)] = Number(event.target.value);
    // setPileFilterProvided(newFilter);
    setObjectProvided({ type: "setPileFilter", payload: newFilter });
    // skip if empty
    if (event.target.value === "") {
      return;
    }
    const projectSettingsInput = {
      extraLengthParamA: newFilter.range[0],
      extraLengthParamB: newFilter.range[1],
      extraLengthParamC: newFilter.range[2],
    };
    try {
      // update project settings
      await API.graphql({
        query: updateProjectSettings,
        variables: {
          projectId: project.id,
          projectSettingsInput,
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
    } catch (e) {
      fileUploadErrorAlert();
    }
  };

  return (
    <Stack spacing={0.2}>
      <Box>
        {/* 1行目 */}
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={2.3}>
            <div style={{ backgroundColor: colors[0] }}>
              <Typography variant="body2" align="center">
                {names[0]}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={4.5}></Grid>
          <Grid item xs={0.8}>
            <FromTo />
          </Grid>
          <Grid item xs={4.3}>
            <ToValue value={objectProvided.pileFilter.range[0]} />
          </Grid>
        </Grid>
        {/* 2行目 */}
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={2.3}>
            <div style={{ backgroundColor: colors[1] }}>
              <Typography variant="body2" align="center">
                {names[1]}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={4.5}>
            <TextField
              id="param0"
              name="0"
              variant="outlined"
              type="number"
              size="small"
              // InputProps={{
              //   endAdornment: (
              //     <InputAdornment position="end">mm</InputAdornment>
              //   ),
              // }}
              fullWidth
              value={objectProvided.pileFilter.range[0]}
              margin="normal"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={0.8}>
            <FromTo />
          </Grid>
          <Grid item xs={4.3}>
            <ToValue value={objectProvided.pileFilter.range[1]} />
          </Grid>
        </Grid>
        {/* 3行目 */}
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={2.3}>
            <div style={{ backgroundColor: colors[2] }}>
              <Typography variant="body2" align="center">
                {names[2]}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={4.5}>
            <TextField
              id="param1"
              name="1"
              variant="outlined"
              type="number"
              // InputProps={{
              //   endAdornment: (
              //     <InputAdornment position="end">mm</InputAdornment>
              //   ),
              // }}
              fullWidth
              value={objectProvided.pileFilter.range[1]}
              size="small"
              margin="normal"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={0.8}>
            <FromTo />
          </Grid>
          <Grid item xs={4.3}>
            <ToValue value={objectProvided.pileFilter.range[2]} />
          </Grid>
        </Grid>
        {/* 4行目 */}
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={2.3}>
            <div style={{ backgroundColor: colors[3] }}>
              <Typography variant="body2" align="center">
                {names[3]}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={4.5}>
            <TextField
              id="param2"
              name="2"
              variant="outlined"
              type="number"
              size="small"
              // InputProps={{
              //   endAdornment: (
              //     <InputAdornment position="end">mm</InputAdornment>
              //   ),
              // }}
              fullWidth
              value={objectProvided.pileFilter.range[2]}
              margin="normal"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={0.8}>
            <FromTo />
          </Grid>
          <Grid item xs={4.3}></Grid>
        </Grid>
      </Box>
    </Stack>
  );
};

export default PileFilter;
