import React ,{ useContext}from "react";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CameraContext from "../contexts/CameraContext";

const TopViewButton = () => {

  const {cameraProvided, setCameraProvided} = useContext(CameraContext);

  const handleChange = (event: React.MouseEvent<HTMLElement, MouseEvent>, newCamera: string) => {
    setCameraProvided(newCamera);
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={cameraProvided}
      size="small"
      exclusive
      onChange={handleChange}
    >
      <ToggleButton value="perspective" size="small">3D</ToggleButton>
      <ToggleButton value="top"  size="small">2D</ToggleButton>
    </ToggleButtonGroup>
  );
};

export default TopViewButton;
