import { useRef, useState, useContext } from "react";
import {
  Stack,
  Typography,
  TextField,
  Button,
  DialogContent,
  DialogActions,
} from "@mui/material";
// Ampify
import { API } from "aws-amplify";
import { createProject, createMeshAndPileSet } from "../../graphql/mutations";
// Contexts
import UploadStatusContext from "../contexts/UploadStatusContext";
import ProgressContext from "../contexts/ProgressContext";
import ProjectContext from "../contexts/ProjectContext";
import ObjectContext from "../contexts/ObjectContext";

// Components
import NewProjectFileUpload from "./NewProjectFileUpload";
import useFillEmptyDesignDialog from "./useFillEmptyDesignDialog";
// Utilities
import { typeCSVforPileInput, PileInputToPile, fillPlanValue, isSoilDepthDesignEmpty } from "../utils/pileUtils";
import { createMeshPoints } from "../utils/meshUtils";
import { createPileGeometry } from "../utils/objectUtils";
import {
  fileUploadErrorAlert,
  fileValidationErrorAlert,
} from "../utils/alertUtils";
import { csvFileToObjectArray } from "../utils/csvUtils";
// Types
import { MeshCSVType } from "../../types/mesh.types";
import {
  PileCSVType,
  PileDataType,
  PileType,
} from "../../types/pile.types";

type NewProjectProps = {
  handleClick: (value: string) => void;
};

const NewProject = (props: NewProjectProps) => {
  // Contexts
  const { setUploadStatusProvided } = useContext(UploadStatusContext);
  const { setProgressProvided } = useContext(ProgressContext);
  const { projectProvided, setProjectProvided } = useContext(ProjectContext);
  const { setObjectProvided } = useContext(ObjectContext);
  // Props
  const { handleClick } = props;
  // Project Name
  const projectName = useRef<HTMLInputElement>(null);
  const handleTextFieldChange = () => {
    if (projectName.current && projectName.current.value === "") {
      setInputError({ ...inputError, projectName: true });
    } else {
      setInputError({ ...inputError, projectName: false });
    }
  };
  // Validation
  const [inputError, setInputError] = useState({
    projectName: true,
    meshFile: true,
    pileFile: true,
  });
  // Dialog
  const { FillEmptyDesignDialog, openFillEmptyDesignDialog } = useFillEmptyDesignDialog();

  // Mesh File
  const [meshFile, setMeshFile] = useState<File | null>(null);
  const handleMeshFileChange = (e: React.ChangeEvent<any>) => {
    if (e.target.files[0] === undefined) {
      setMeshFile(null);
      setInputError({ ...inputError, meshFile: true });
      return;
    }
    setMeshFile(e.target.files[0]);
    setInputError({ ...inputError, meshFile: false });
  };
  // Pile File
  const [pileFile, setPileFile] = useState<File | null>(null);
  const handlePileFileChange = (e: React.ChangeEvent<any>) => {
    if (e.target.files[0] === undefined) {
      setPileFile(null);
      setInputError({ ...inputError, pileFile: true });
      return;
    }
    setPileFile(e.target.files[0]);
    setInputError({ ...inputError, pileFile: false });
  };

  // 作成ボタンを押したときの処理
  const createNewProject = async () => {
    const projName = projectName.current?.value ?? "";
    if (projName !== "") {
      // convert mesh and pile csv file to object array
      const meshObjectArray: MeshCSVType[] = await (async () => {
        if (meshFile === null) {
          fileValidationErrorAlert("mesh");
          throw new Error("mesh file validation error");
        }
        try {
          return await csvFileToObjectArray(meshFile, "mesh") as MeshCSVType[];
        } catch (e) {
          fileValidationErrorAlert("mesh");
          throw new Error("mesh file validation error");
        }
      })();
      let pileObjectArray: PileCSVType[] = await (async () => {
        if (pileFile === null) {
          fileValidationErrorAlert("pile");
          throw new Error("pile file validation error");
        }
        try {
          return await csvFileToObjectArray(pileFile, "pile") as PileCSVType[];
        } catch (e) {
          fileValidationErrorAlert("pile");
          throw new Error("pile file validation error");
        }
      })();
      // check if 支持層深度（設計）and 根入れ余長（設計）are empty
      if (
        isSoilDepthDesignEmpty(pileObjectArray)
      ) {
        const result = await openFillEmptyDesignDialog();
        switch (result) {
          case "yes":
            // fill empty design
            pileObjectArray = fillPlanValue(pileObjectArray);
            break;
          case "no":
            break;
        }
      }
      const meshObjectArrayWithType = meshObjectArray.map(
        (mesh: MeshCSVType) => {
          return {
            x: parseFloat(mesh.x),
            y: parseFloat(mesh.y),
            z: parseFloat(mesh.z),
          };
        }
      );
      const pileObjectArrayWithType = typeCSVforPileInput(pileObjectArray);
      try {
          // create project
          const projectRes: any = await API.graphql({
            query: createProject,
            variables: { projectInput: { name: projName } },
            authMode: "AMAZON_COGNITO_USER_POOLS",
          });
          const project = projectRes.data.createProject;
          console.log(project.id);
          // post mesh and pile data
          const meshAndPileSetRes: any = await API.graphql({
            query: createMeshAndPileSet,
            variables: {
              projectId: project.id,
              meshInput: { points: meshObjectArrayWithType },
              pilesInput: pileObjectArrayWithType,
            },
            authMode: "AMAZON_COGNITO_USER_POOLS",
          });
          console.log(meshAndPileSetRes.data.CreateMesh);
          console.log(meshAndPileSetRes.data.CreatePileSet);
          const pileSet = meshAndPileSetRes.data.CreatePileSet;
          // create mesh points
          const initialMeshPoints = createMeshPoints(meshObjectArrayWithType);
          // create pile object
          const piles = PileInputToPile(pileObjectArrayWithType);
          const pilePoints = piles.map((pile: PileType) => {
            return { props: pile } as PileDataType;
          });
          const { pileObjectArray: pileObjectArrayWithInfo, pointsAddToMesh } =
            createPileGeometry(pilePoints);
          // set projectProvided
          setProjectProvided({
            ...projectProvided,
            project,
            isPileNewest: true,
            pileFiles: [pileSet],
            selectedPile: pileSet,
          });
          // set marginOptionProvided
          setObjectProvided({
            type: "openProject",
            payload: {
              pileMarginOption: {
                method: project.settings.pileIndexParam,
                param1: project.settings.pileParamA,
                param2: project.settings.pileParamB,
                param3: project.settings.pileParamC,
                param4: project.settings.pileParamD,
              },
              pileFilter: {
                range: [
                  project.settings.extraLengthParamA,
                  project.settings.extraLengthParamB,
                  project.settings.extraLengthParamC,
                ],
                visibleStatus: project.settings.extraLengthVisible,
              },
              meshColor: {
                colorMap: project.settings.meshColor,
                method: project.settings.meshIndexParam,
              },
              mesh: {
                initialPoints: initialMeshPoints,
                pointsAddToMesh: pointsAddToMesh,
              },
              piles: pileObjectArrayWithInfo,
            },
          });
        // close dialog
        setUploadStatusProvided(true);
        handleClick("close");
      } catch (e) {
        console.log(e);
        fileUploadErrorAlert();
      }
    }
  };

  return (
    <>
      <DialogContent>
        <Stack spacing={1.0}>
          <Typography variant="subtitle1">プロジェクト名</Typography>
          <TextField
            id="projectName"
            variant="outlined"
            size="small"
            sx={{ width: "100%" }}
            defaultValue=""
            inputRef={projectName}
            helperText={
              inputError.projectName ? "プロジェクト名を入力してください" : ""
            }
            required
            error={inputError.projectName}
            onChange={handleTextFieldChange}
          />
          <Typography variant="subtitle1">地盤調査結果</Typography>
          <NewProjectFileUpload
            file={meshFile}
            handleChange={handleMeshFileChange}
          />
          <Typography variant="subtitle1">杭リスト</Typography>
          <NewProjectFileUpload
            file={pileFile}
            handleChange={handlePileFileChange}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            handleClick("option");
          }}
        >
          戻る
        </Button>
        <Button
          variant="contained"
          disabled={Object.values(inputError).some((item) => item)}
          onClick={async () => {
            setProgressProvided(true);
            try {
              await createNewProject();
            } catch (e) {
              console.log(e);
            }
            setProgressProvided(false);
          }}
        >
          作成
        </Button>
      </DialogActions>
      <FillEmptyDesignDialog
        title="確認"
        message="支持層深度（設計）・根入れ余長（設計）の列が空白です。ANAGO Mobileで計算した値を自動入力しますか？"
      />
    </>
  );
};

export default NewProject;
