import { type Vector3 } from "three";

import {
  getPilePointsAddToMesh,
  setPileData,
} from "../utils/pileUtils";
import { PileDataType } from "../../types/pile.types";

const createPileGeometry = (pileObjectArray: PileDataType[]):{
  pileObjectArray: PileDataType[];
  pointsAddToMesh: Vector3[];
} => {
  pileObjectArray = setPileData(pileObjectArray);
    // meshに追加するPointsを取得
    const pointsAddToMesh = getPilePointsAddToMesh(pileObjectArray) as Vector3[];
    return {pileObjectArray, pointsAddToMesh};
  };


export { createPileGeometry };
