import React, { useContext, useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  ThemeProvider,
} from "@mui/material";
// Context
import ProjectContext from "../contexts/ProjectContext";
import ObjectContext from "../contexts/ObjectContext";
// Theme
import { darkTheme } from "../darkTheme";
import PilePanelContext from "../contexts/PilePanelContext";
// Type
import type { DialogProps } from "@mui/material";

type Result = "yes" | "no";

type FillEmptyDesignDialogProps = {
  open: boolean;
  onClose: (result: Result) => void;
  title: string;
  message: string;
};

const handleClose = (event: React.MouseEvent<HTMLInputElement>, reason: 'backdropClick') => {
  if ( reason === 'backdropClick') return;
};

const FILLEMPTYDESIGN_DIALOG = ({ open, onClose, title, message }: FillEmptyDesignDialogProps) => (
  <Dialog open={open} onClose={handleClose}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{message}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => onClose("no")} variant="outlined">
        キャンセル
      </Button>
      <Button
        onClick={() => onClose("yes")}
        variant="contained"
        autoFocus
      >
        はい
      </Button>
    </DialogActions>
  </Dialog>
);

const useFillEmptyDesignDialog = () => {
  const [open, setOpen] = useState(false);
  const [resolve, setResolve] = useState<
    ((value: Result | PromiseLike<Result>) => void) | null
  >(null);

  const openFillEmptyDesignDialog = () => {
    setOpen(true);
    return new Promise<Result>((res) => {
      setResolve(() => res);
    });
  };

  const FillEmptyDesignDialog = (props: Omit<FillEmptyDesignDialogProps, "open" | "onClose">) => {
    // Context
    const { projectProvided } = useContext(ProjectContext);
    const { objectProvided, setObjectProvided } = useContext(ObjectContext);
    const { setPilePanelProvided } = useContext(PilePanelContext);

    const onClose = (result: Result) => {
      switch (result) {
        case "yes":
          setPilePanelProvided(true);
          break;
        case "no":
          setPilePanelProvided(true);
          break;
      }
      // setObjectProvided({
      //   type: "setStashedPile",
      //   payload: undefined,
      // });
      setOpen(false);
      if (resolve) {
        resolve(result);
      }
    };

    useEffect(() => {
      if (projectProvided.selectedPile && objectProvided.stashedPile) {
        setOpen(true);
      }
    }, [projectProvided.selectedPile, objectProvided.stashedPile]);
    return (
      <ThemeProvider theme={darkTheme}>
        <FILLEMPTYDESIGN_DIALOG
          open={open}
          onClose={onClose}
          title={props.title}
          message={props.message}
        />
      </ThemeProvider>
    );
  };

  return {
    FillEmptyDesignDialog,
    openFillEmptyDesignDialog,
  };
};

export default useFillEmptyDesignDialog;
