import * as THREE from 'three';
import { useRef, useContext } from 'react';
import { setMeshColor } from '../utils/meshUtils';
import { grey } from "@mui/material/colors";
// Context
import ObjectContext from "../contexts/ObjectContext";

// ドロネー
const Delauney = () =>{
    // const ref = useRef();
    // Context
    const { objectProvided } = useContext(ObjectContext);
    const color = objectProvided.meshColor;
    const points = objectProvided.mesh.points;
    const geometry = objectProvided.mesh.geometry;
    const distance = objectProvided.mesh.distance;
    const geom = setMeshColor(geometry, color, points, distance);

    const wireMaterial = new THREE.MeshPhysicalMaterial({ color: grey['50'], wireframe: true});
    // const wireMaterial = new THREE.MeshBasicMaterial({ color: grey[0], wireframe:true});
    // const meshMaterial = new THREE.MeshPhysicalMaterial({ vertexColors: THREE.VertexColors, wireframe: false , side: THREE.DoubleSide, transparent: true, opacity: 0.9});
    const meshMaterial = new THREE.MeshPhysicalMaterial({ vertexColors: true, wireframe: false , side: THREE.DoubleSide, transparent: true, opacity: 0.9});

    return(
        <>
            {/* <mesh ref={ref} geometry ={geom} material={wireMaterial} castShadow />
            <mesh ref={ref} geometry ={geom} material={meshMaterial} castShadow /> */}
            <mesh  geometry ={geom} material={wireMaterial} castShadow />
            <mesh  geometry ={geom} material={meshMaterial} castShadow />
        </>
    )
};

export default Delauney;