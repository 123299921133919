import * as React from "react";
import { useContext } from "react";
import { useTheme, createTheme, ThemeProvider, Theme, CustomPalette, CustomPaletteColor, ThemeOptions } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { names, panelColors } from "../config/PileConfig";
// Contexts
import ProjectContext from "../contexts/ProjectContext";
import ObjectContext from "../contexts/ObjectContext";
// Utilities
import { fileUploadErrorAlert } from "../utils/alertUtils";
// Ampify
import { API } from "aws-amplify";
import { updateProjectSettings } from "../../graphql/mutations";

declare module '@mui/material/styles' {
  interface CustomTheme {
    palette: CustomPalette;
  }

  interface CustomPalette {
    primary: CustomPaletteColor;
    neutral: CustomPaletteColor;
    0: CustomPaletteColor;
    1: CustomPaletteColor;
    2: CustomPaletteColor;
    3: CustomPaletteColor;
  }

  interface CustomPaletteColor {
    darker?: string;
  }
  interface Palette {
    neutral: Palette['primary'] & CustomPaletteColor;
    0: Palette['primary'] & CustomPaletteColor;
    1: Palette['primary'] & CustomPaletteColor;
    2: Palette['primary'] & CustomPaletteColor;
    3: Palette['primary'] & CustomPaletteColor;
  }
  interface PaletteOptions {
    neutral?: PaletteOptions['primary'] & CustomPaletteColor;
    0?: PaletteOptions['primary'] & CustomPaletteColor;
    1?: PaletteOptions['primary'] & CustomPaletteColor;
    2?: PaletteOptions['primary'] & CustomPaletteColor;
    3?: PaletteOptions['primary'] & CustomPaletteColor;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    neutral: true;
    0: true;
    1: true;
    2: true;
    3: true;
  }
}

const colors = panelColors;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const colorToHex = (color: string) => {
  return color;
};

const chipTheme = createTheme({
  palette: {
    primary: {
      main: "#0971f1",
      darker: "#053e85",
    } as CustomPaletteColor,
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
    0: {
      main: colorToHex(colors[0]),
      contrastText: "#fff",
    },
    1: {
      main: colorToHex(colors[1]),
      contrastText: "#fff",
    },
    2: {
      main: colorToHex(colors[2]),
      contrastText: "#fff",
    },
    3: {
      main: colorToHex(colors[3]),
      contrastText: "#fff",
    },
  } as CustomPalette,
} as ThemeOptions);

const PileVisivility = () => {
  const theme = useTheme();
  // Contexts
  const { projectProvided } = useContext(ProjectContext);
  const { objectProvided, setObjectProvided } = useContext(ObjectContext);
  // Change Pile File
  const handleChange = async (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    const project = projectProvided.project;
    const newPileFilter = {
      ...objectProvided.pileFilter,
      visibleStatus: typeof value === "string" ? value.split(",") : value,
    };
    setObjectProvided({ type: "setPileFilter", payload: newPileFilter });
    const projectSettingsInput = {
      extraLengthVisible: newPileFilter.visibleStatus,
    };
    try {
      // update project settings
      await API.graphql({
        query: updateProjectSettings,
        variables: {
          projectId: project.id,
          projectSettingsInput,
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
    } catch (e) {
      fileUploadErrorAlert();
    }
  };

  return (
    <div>
      <FormControl fullWidth>
        <InputLabel id="demo-multiple-chip-label">
          表示するステータス
        </InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={objectProvided.pileFilter.visibleStatus}
          size="small"
          onChange={handleChange}
          input={
            <OutlinedInput
              id="select-multiple-chip"
              label="表示するステータス"
            />
          }
          renderValue={(selected) => (
            <ThemeProvider theme={chipTheme}>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={value}
                    color={String(names.indexOf(value))}
                    size="small"
                  />
                ))}
              </Box>
            </ThemeProvider>
          )}
          MenuProps={MenuProps}
        >
          {names.map((name, index) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, objectProvided.pileFilter.visibleStatus, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default PileVisivility;
