const pileJpnHeader: string[] = [
  "杭番号",
  "杭符号",
  "杭径(mm)",
  "工区名",
  "杭施工順序",
  "杭芯X座標(mm)",
  "杭芯Y座標(mm)",
  "杭天レベル（設計）(mm)",
  "杭長（設計）(mm)",
  "必要根入れ長さ（設計）",
  "支持層深度（設計）",
  "根入れ余長（設計）",
  "中杭有無（計画）",
  "杭天レベル（計画）",
  "杭長（計画）",
  "必要根入れ長さ（計画）",
  "支持層深度（計画）",
  "根入れ余長（計画）",
  "杭天レベル（実施）",
  "杭長（実施）",
  "支持層深度（実施）FL基準",
  "根入れ余長（実施）",
  "杭打設日8桁で記入",
  "備考",
];

const pileExportHeader: string[] = [
  "index",
  "symbol",
  "diameter",
  "work_area",
  "order",
  "x",
  "y",
  "top_level_design",
  "pile_length_design",
  "min_embedment_design",
  "soil_depth_design",
  "extra_embedment_design",
  "has_middle_plan",
  "top_level_plan",
  "pile_length_plan",
  "min_embedment_plan",
  "soil_depth_plan",
  "extra_embedment_plan",
  "top_level_actual",
  "pile_length_actual",
  "soil_depth_actual",
  "extra_embedment_actual",
  "date_of_work",
  "description",
];

const formatter: Intl.NumberFormat = new Intl.NumberFormat("ja-JP", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

type PileHeaderConfig = {
  label: string;
  dataKey: string;
  width: number;
  numeric: boolean;
  format?: (value: string) => string;
};

const pileHeaderConfig: PileHeaderConfig[] = [
  {
    label: "杭番号",
    dataKey: "index",
    width: 80,
    numeric: false,
  },
  {
    label: "杭符号",
    dataKey: "symbol",
    width: 80,
    numeric: false,
  },
  {
    label: "杭径(mm)",
    dataKey: "diameter",
    width: 80,
    numeric: true,
    format: (value: string) =>
      Number.isNaN(parseFloat(value))
        ? ""
        : formatter.format(parseFloat(value)),
  },
  {
    label: "工区名",
    dataKey: "work_area",
    width: 120,
    numeric: false,
  },
  {
    label: "杭施工順序",
    dataKey: "order",
    width: 120,
    numeric: true,
    format: (value: string) =>
      Number.isNaN(parseFloat(value))
        ? ""
        : formatter.format(parseFloat(value)),
  },
  {
    label: "杭芯X座標(mm)",
    dataKey: "x",
    width: 120,
    numeric: true,
    format: (value: string) =>
      Number.isNaN(parseFloat(value))
        ? ""
        : formatter.format(parseFloat(value)),
  },
  {
    label: "杭芯Y座標(mm)",
    dataKey: "y",
    width: 120,
    numeric: true,
    format: (value: string) =>
      Number.isNaN(parseFloat(value))
        ? ""
        : formatter.format(parseFloat(value)),
  },
  {
    label: "杭天レベル（設計）(mm)",
    dataKey: "top_level_design",
    width: 120,
    numeric: true,
    format: (value: string) =>
      Number.isNaN(parseFloat(value))
        ? ""
        : formatter.format(parseFloat(value)),
  },
  {
    label: "杭長（設計）(mm)",
    dataKey: "pile_length_design",
    width: 120,
    numeric: true,
    format: (value: string) =>
      Number.isNaN(parseFloat(value))
        ? ""
        : formatter.format(parseFloat(value)),
  },
  {
    label: "必要根入れ長さ（設計）",
    dataKey: "min_embedment_design",
    width: 120,
    numeric: true,
    format: (value: string) =>
      Number.isNaN(parseFloat(value))
        ? ""
        : formatter.format(parseFloat(value)),
  },
  {
    label: "支持層深度（設計）",
    dataKey: "soil_depth_design",
    width: 120,
    numeric: true,
    format: (value: string) =>
      Number.isNaN(parseFloat(value))
        ? ""
        : formatter.format(parseFloat(value)),
  },
  {
    label: "根入れ余長（設計）",
    dataKey: "extra_embedment_design",
    width: 120,
    numeric: true,
    format: (value: string) =>
      Number.isNaN(parseFloat(value))
        ? ""
        : formatter.format(parseFloat(value)),
  },
  {
    label: "中杭有無（計画）",
    dataKey: "has_middle_plan",
    width: 80,
    numeric: false,
  },
  {
    label: "杭天レベル（計画）",
    dataKey: "top_level_plan",
    width: 120,
    numeric: true,
    format: (value: string) =>
      Number.isNaN(parseFloat(value))
        ? ""
        : formatter.format(parseFloat(value)),
  },
  {
    label: "杭長（計画）",
    dataKey: "pile_length_plan",
    width: 120,
    numeric: true,
    format: (value: string) =>
      Number.isNaN(parseFloat(value))
        ? ""
        : formatter.format(parseFloat(value)),
  },
  {
    label: "必要根入れ長さ（計画）",
    dataKey: "min_embedment_plan",
    width: 120,
    numeric: true,
    format: (value: string) =>
      Number.isNaN(parseFloat(value))
        ? ""
        : formatter.format(parseFloat(value)),
  },
  {
    label: "支持層深度（計画）",
    dataKey: "soil_depth_plan",
    width: 120,
    numeric: true,
    format: (value: string) =>
      Number.isNaN(parseFloat(value))
        ? ""
        : formatter.format(parseFloat(value)),
  },
  {
    label: "根入れ余長（計画）",
    dataKey: "extra_embedment_plan",
    width: 120,
    numeric: true,
    format: (value: string) =>
      Number.isNaN(parseFloat(value))
        ? ""
        : formatter.format(parseFloat(value)),
  },
  {
    label: "杭天レベル（実施）",
    dataKey: "top_level_actual",
    width: 120,
    numeric: true,
    format: (value: string) =>
      Number.isNaN(parseFloat(value))
        ? ""
        : formatter.format(parseFloat(value)),
  },
  {
    label: "杭長（実施）",
    dataKey: "pile_length_actual",
    width: 120,
    numeric: true,
    format: (value: string) =>
      Number.isNaN(parseFloat(value))
        ? ""
        : formatter.format(parseFloat(value)),
  },
  {
    label: "支持層深度（実施）FL基準",
    dataKey: "soil_depth_actual",
    width: 120,
    numeric: true,
    format: (value: string) =>
      Number.isNaN(parseFloat(value))
        ? ""
        : formatter.format(parseFloat(value)),
  },
  {
    label: "根入れ余長（実施）",
    dataKey: "extra_embedment_actual",
    width: 120,
    numeric: true,
    format: (value: string) =>
      Number.isNaN(parseFloat(value))
        ? ""
        : formatter.format(parseFloat(value)),
  },
  {
    label: "杭打設日8桁で記入",
    dataKey: "date_of_work",
    width: 120,
    numeric: false,
  },
  {
    label: "備考",
    dataKey: "description",
    width: 250,
    numeric: false,
  },
];

const meshJpnHeader: string[] = ["X", "Y", "Z"];

const meshExportHeader: string[] = ["x", "y", "z"];

const csvHeaderToJpHeader = (header: string): string | null => {
  const config = pileHeaderConfig.find((config) => config.dataKey === header);
  return config ? config.label : null;
};

const jpHeaderToCsvHeader = (header: string): string | null => {
  const config = pileHeaderConfig.find((config) => config.label === header);
  return config ? config.dataKey : null;
};

export {
  pileHeaderConfig,
  pileJpnHeader,
  pileExportHeader,
  meshJpnHeader,
  meshExportHeader,
  csvHeaderToJpHeader,
  jpHeaderToCsvHeader,
};
