import React, { useContext, useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  ThemeProvider,
} from "@mui/material";
// Context
import ProjectContext from "../contexts/ProjectContext";
import ObjectContext from "../contexts/ObjectContext";
// Theme
import { darkTheme } from "../darkTheme";
import PilePanelContext from "../contexts/PilePanelContext";
// Type
import type { DialogProps } from "@mui/material";

type Result = "confirm" | "cancel";

type StashDialogProps = {
  open: boolean;
  onClose: (result: Result) => void;
  title: string;
  message: string;
};

const handleClose = (event: React.MouseEvent<HTMLInputElement>, reason: 'backdropClick') => {
  if ( reason === 'backdropClick') return;
};

const STASH_DIALOG = ({ open, onClose, title, message }: StashDialogProps) => (
  <Dialog open={open} onClose={handleClose}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{message}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => onClose("cancel")} variant="outlined">
        キャンセル
      </Button>
      <Button
        onClick={() => onClose("confirm")}
        variant="contained"
        color="error"
        autoFocus
      >
        復元
      </Button>
    </DialogActions>
  </Dialog>
);

const useStashDialog = () => {
  const [open, setOpen] = useState(false);
  const [resolve, setResolve] = useState<
    ((value: Result | PromiseLike<Result>) => void) | null
  >(null);

  const openStashDialog = () => {
    setOpen(true);
    return new Promise<Result>((res) => {
      setResolve(() => res);
    });
  };

  const StashDialog = (props: Omit<StashDialogProps, "open" | "onClose">) => {
    // Context
    const { projectProvided } = useContext(ProjectContext);
    const { objectProvided, setObjectProvided } = useContext(ObjectContext);
    const { setPilePanelProvided } = useContext(PilePanelContext);

    const onClose = (result: Result) => {
      switch (result) {
        case "confirm":
          // setObjectProvided({
          //   type: "setSelectedPile",
          //   payload: { id: objectProvided.stashedPile?.props.index },
          // });
          console.log(
            "stashDialog: setSelectedPile",
            objectProvided.stashedPile?.props.index
          );
          // setPilePanelProvided(false);
          break;
        case "cancel":
          setPilePanelProvided(true);
          break;
      }
      setObjectProvided({
        type: "setStashedPile",
        payload: undefined,
      });
      setOpen(false);
      if (resolve) {
        resolve(result);
      }
    };

    useEffect(() => {
      if (projectProvided.selectedPile && objectProvided.stashedPile) {
        setOpen(true);
      }
    }, [projectProvided.selectedPile, objectProvided.stashedPile]);
    return (
      <ThemeProvider theme={darkTheme}>
        <STASH_DIALOG
          open={open}
          onClose={onClose}
          title={props.title}
          message={props.message}
        />
      </ThemeProvider>
    );
  };

  return {
    StashDialog,
    openStashDialog,
  };
};

export default useStashDialog;
