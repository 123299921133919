import React, { useState, useEffect, useContext } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { darkTheme } from "./darkTheme";
// Contexts
import ProjectContext from "./contexts/ProjectContext";
// MUI
import {
  ThemeProvider,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
// Icons
import CloseIcon from '@mui/icons-material/Close';
// Items
import NewPileSet from "./items/NewPileSet";
import UpdateMesh from "./items/UpdateMesh";

const UpdatePageDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const UpdatePageDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
    </DialogTitle>
  );
};

UpdatePageDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const UpdatePage = ({ initialStatus = "newPileSet", open, setOpen}) => {
    const [status, setStatus] = useState(initialStatus);
    // Auth
    const { user } = useAuthenticator((context) => [context.user]);
      // Contexts
  const { projectProvided } = useContext(ProjectContext);
    useEffect(() => {
      Auth.currentSession().then((user) => {
        const { payload } = user.getIdToken();
        if (payload && payload["cognito:groups"]) {
          setOpen(
            payload["cognito:groups"].includes(
              "User_" + projectProvided.project.id
            ) || payload["cognito:groups"].includes("Admin")
          );
        }
      });
    }, [user, projectProvided]);
  
    const handleClick = (value) => {
      switch (value) {
        case "newPileSet":
          setStatus("newPileSet");
          break;
          case "updateMesh":
            setStatus("updateMesh");
            break;
        case "close":
          setOpen(false);
          break;
        default:
          setOpen(false);
          break;
      }
    };
  
    const handleCloseClick = () => {
      handleClick("close");
    }
  
    const item = () => {
      switch (status) {
        case "newPileSet":
          return <NewPileSet handleClick={handleClick}/>;
        case "updateMesh":
          return <UpdateMesh handleClick={handleClick}/>;
        default:
          console.log(status);
          return <div>default</div>;
      }
    };
  return (
    <ThemeProvider theme={darkTheme}>
      <UpdatePageDialog
        fullWidth
        maxWidth={"sm"}
        onClose={() => {}}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <UpdatePageDialogTitle id="customized-dialog-title" onClose={() => {}}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="title">ANAGO Mobile</Typography>
            </Grid>
            <Grid item>
              <IconButton size="small">
                <CloseIcon onClick={handleCloseClick}/>
              </IconButton>
            </Grid>
          </Grid>
        </UpdatePageDialogTitle>
        <Divider />
        {item()}
      </UpdatePageDialog>
    </ThemeProvider>
  )
}

export default UpdatePage