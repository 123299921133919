import { useMemo } from "react";
import { getTextColor } from "../utils/pileUtils";

type PinProps = {
  children: string;
  position: [number, number, number];
  fontSize?: number;
  visible?: boolean;
  align?: CanvasTextAlign;
  scale?: [number, number, number];
  fillColor?: string;
  textColor?: string;
  circleColor?: string;
  isStashed?: boolean;
};

const Pin = ({
  children,
  position,
  fontSize = 30,
  visible = true,
  align = "center",
  scale = [1, 1, 1],
  fillColor = "blue",
  textColor = getTextColor(fillColor),
  circleColor = "white",
  isStashed = false,
}: PinProps) => {
  const canvas = useMemo(() => {
    var canvas = document.createElement("canvas");
    canvas.width = 100;
    canvas.height = 100;
    const ctx = canvas.getContext("2d");
    if (!ctx) return canvas;
    const x = canvas.width / 2;
    const y = canvas.height / 2;
    const radius = 45;
    const startAngle = 0;
    const endAngle = Math.PI * 2;

    // canvasの大きさ
    // ctx.rect(0, 0, canvas.width, canvas.height);
    // ctx.strokeStyle = "black";
    // ctx.lineWidth = 10;
    // ctx.stroke();

    ctx.fillStyle = fillColor;
    // const col = new THREE.Color(fillColor);
    // const blueCol = new THREE.Color("blue");
    // const co = new THREE.Color();
    // console.log(col.getHSL(co));
    // console.log(new THREE.Color().getHSL(blueCol))
    // ctx.fillStyle = 'rgb(0, 0, 255)';
    // 塗りつぶし円
    ctx.beginPath();
    ctx.arc(x, y, radius, startAngle, endAngle);
    ctx.fill();

    // 線円
    ctx.strokeStyle = isStashed ? "red" : circleColor;
    ctx.lineWidth = 10;
    ctx.beginPath();
    ctx.arc(x, y, radius, startAngle, endAngle);
    ctx.stroke();

    // 文字
    ctx.fillStyle = textColor;
    ctx.font = `${fontSize}px sans-serif`;
    ctx.textAlign = align;
    ctx.textBaseline = "middle";
    ctx.fillText(children, x, y);

    return canvas;
  }, [circleColor, fillColor, textColor, isStashed]);

  return (
    <sprite scale={scale} position={position} visible={visible} name="pin">
      <spriteMaterial attach="material" transparent alphaTest={0.5} color={0xeeeeee} >
        <canvasTexture attach="map" image={canvas} />
      </spriteMaterial>
    </sprite>
  );
};

export default Pin;
