import React, { useState, useEffect, useContext, Fragment } from "react";
// MUI
import {
  Autocomplete,
  TextField,
  CircularProgress,
  Typography,
} from "@mui/material";
import HistoryIcon from "@mui/icons-material/History";
import FiberNewIcon from "@mui/icons-material/FiberNew";
// Ampify
import { API } from "aws-amplify";
import { getPilesByPileSetId } from "../../graphql/queries";
// Contexts
import ProjectContext from "../contexts/ProjectContext";
import PilePanelContext from "../contexts/PilePanelContext";
import ProgressContext from "../contexts/ProgressContext";
import ObjectContext from "../contexts/ObjectContext";
// Utilities
import { createPileGeometry } from "../utils/objectUtils";
import { fileListErrorAlert } from "../utils/alertUtils";
import { PileInputToPile } from "../utils/pileUtils";
import { replaceNullWithNaN } from "../utils/csvUtils";

const PileFileOpen = (props) => {
  // State
  const [selectedValue, setSelectedValue] = useState({
    name: "",
    path: "",
    key: "",
    index: -1,
  });
  // Context
  const { projectProvided, setProjectProvided } = useContext(ProjectContext);
  const { setPilePanelProvided } = useContext(PilePanelContext);
  const { setProgressProvided } = useContext(ProgressContext);
  const { setObjectProvided } = useContext(ObjectContext);
  // Autocomplete
  const [open, setOpen] = useState(false);
  const loading = false;
  // Props
  const defaultProps = {
    options: projectProvided.pileFiles,
    getOptionLabel: (option) => option.name,
  };

  // 杭ファイルリストが変更されたときに最初のファイルを選択する
  useEffect(() => {
    if (projectProvided.pileFiles.length > 0) {
      setSelectedValue(projectProvided.pileFiles[0]);
    }
  }, [projectProvided.pileFiles]);

  // Change Pile File
  const changePileFile = async (value, index) => {
    setProgressProvided(true);
    if (value.name.length > 0) {
      try {
        // Panelを閉じる
        setPilePanelProvided(true);
        setProjectProvided({
          ...projectProvided,
          isPileNewest: index === 0,
          selectedPile: value,
        });
        const pilesRes = await API.graphql({
          query: getPilesByPileSetId,
          variables: {
            projectId: projectProvided.project.id,
            pileSetId: value.id,
          },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
        if (pilesRes.data.getPilesByPileSetId === null) {
          return;
        }
        const pilesObjectArray = pilesRes.data.getPilesByPileSetId.map(
          (pile) => {
            return replaceNullWithNaN(pile);
          }
        );
        const piles = PileInputToPile(pilesObjectArray);
        // create pile object
        const pilePoints = piles.map((pile) => {
          return { props: pile };
        });
        const { pileObjectArray: pileObjectArrayWithInfo, pointsAddToMesh } =
          createPileGeometry(pilePoints);
        setObjectProvided({
          type: "openPileFile",
          payload: {
            mesh: {
              pointsAddToMesh,
            },
            piles: pileObjectArrayWithInfo,
          },
        });
      } catch (error) {
        fileListErrorAlert();
      }
    }
    setProgressProvided(false);
  };

  // Header Icon
  const headerIcon = (index) => {
    switch (index) {
      case -1:
        return <></>;
      case 0:
        return <FiberNewIcon />;
      default:
        return <HistoryIcon color="disabled" />;
    }
  };

  return (
    <>
      <Autocomplete
        {...defaultProps}
        id="autocomplete-pileset-list"
        value={selectedValue}
        disableClearable
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        loading={loading}
        noOptionsText={"杭リストがありません"}
        renderInput={(params) => (
          <TextField
            {...params}
            label="ファイル選択"
            variant="standard"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <Fragment>{headerIcon(selectedValue.index)}</Fragment>
              ),
              endAdornment: (
                <Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </Fragment>
              ),
            }}
          />
        )}
        renderOption={(props, option) => (
          <li {...props}>
            {headerIcon(option.index)}
            <Typography>{option.name}</Typography>
          </li>
        )}
        onChange={async (event, newValue) => {
          setSelectedValue(newValue);
          console.log(projectProvided.project.id);
          console.log(newValue.id);

          const index = projectProvided.pileFiles.indexOf(newValue);
          changePileFile(newValue, index);
        }}
      />
    </>
  );
};

export default PileFileOpen;
