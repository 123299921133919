import React from "react";
import { darkTheme } from "../darkTheme";
import { ThemeProvider, Typography } from "@mui/material";

type LegendProps = {
  min: number;
  max: number;
  colorScale: string[];
};

const Legend = ({ min, max, colorScale }: LegendProps) => {
  const gradientStyle = {
    background: `linear-gradient(to right, ${colorScale.join(", ")})`,
    width: "250px",
    height: "20px",
  };

  const containerStyle: React.CSSProperties = {
    position: "absolute",
    bottom: "20px",
    left: "20px",
    background: "rgba(0, 0, 0, 0.7)",
    padding: "10px",
    borderRadius: "5px",
    color: "white",
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <div style={containerStyle}>
        <div style={gradientStyle}></div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="subtitle1">{min.toFixed(1)}m</Typography>
          <Typography variant="subtitle1">{max.toFixed(1)}m</Typography>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default Legend;
