import { useEffect, useState } from "react";
import { List, ListItem, ListItemText } from "@mui/material";
// Amplify
import { Auth } from "aws-amplify";
import { useAuthenticator } from "@aws-amplify/ui-react";

type TopPageOptionProps = {
  handleClick: (type: string) => void;
};

const TopPageOption = ({handleClick}: TopPageOptionProps) => {
  const { user } = useAuthenticator((context) => [context.user]);
  const [isAdmin, setIsAdmin] = useState(false);
  useEffect(() => {
    Auth.currentSession().then((user) => {
      const { payload } = user.getIdToken();
      if (payload && payload["cognito:groups"]) {
        setIsAdmin(payload["cognito:groups"].includes("Admin"));
      }
    });
  }, [user]);
  return (
    <List sx={{ pt: 0 }}>
      {isAdmin && (
        <ListItem button onClick={() => handleClick("new")} key={"new"}>
          <ListItemText primary={"新規作成"} />
        </ListItem>
      )}
      <ListItem button onClick={() => handleClick("existing")} key={"existing"}>
        <ListItemText primary={"プロジェクトを開く"} />
      </ListItem>
    </List>
  );
};

export default TopPageOption;
