import { useState } from "react";
// MUI
import {
  Card,
  CardContent,
  Typography,
  ThemeProvider,
  Stack,
  Collapse,
  ListItemButton,
  ListItemText,
  Divider,
  IconButton,
  Grid,
} from "@mui/material";
// Icons
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";

import { styled } from "@mui/material/styles";
import { darkTheme } from "./darkTheme";
// Options
import WorkArea from "./items/WorkArea";
import MeshOption from "./items/MeshOption";
import MeshColorOption from "./items/MeshColorOption";
import MarginOption from "./items/MarginOption";
import PileVisivility from "./items/PileVisibility";
import PileFilter from "./items/PileFilter";
import ProjectOpen from "./items/ProjectOpen";
import PileFileOpen from "./items/PileFileOpen";

const minWidth = 250;
const maxWidth = 300;

const LeftPanelContainer = styled("div")({
  width: "22%",
  minWidth: minWidth,
  maxWidth: maxWidth,
  height: "55vh",
  position: "absolute",
  float: "left",
  zIndex: "1",
  // margin: "10px",
  top: 10 + 64,
  left: 10,
});

const LeftPanel = () => {
  const [panelOpen, setPanelOpen] = useState(true);
  const [open, setOpen] = useState({
    fileUpload: false,
    fileOpen: true,
    meshOption: false,
    pileOption: false,
  });
  const handleClick = (type) => {
    setOpen({ ...open, [type]: !open[type] });
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <LeftPanelContainer>
        <Card sx={{ minWidth: minWidth }}>
          <CardContent>
            <Stack spacing={0.2}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={9}>
                  <Typography variant="subtitle1">設定</Typography>
                </Grid>
                <Grid item xs={2}>
                  <IconButton onClick={() => setPanelOpen(!panelOpen)}>
                    {panelOpen ? <CloseFullscreenIcon /> : <OpenInNewIcon />}
                  </IconButton>
                </Grid>
              </Grid>
              <Collapse in={panelOpen} timeout="auto" unmountOnExit>
                <Divider />
                <div>
                  <ListItemButton onClick={() => handleClick("fileOpen")}>
                    <ListItemText primary="プロジェクトを選択" id="fileOpen" />
                    {open.fileOpen ? (
                      <ExpandLess id="fileOpen" />
                    ) : (
                      <ExpandMore id="fileOpen" />
                    )}
                  </ListItemButton>
                  <Collapse in={open.fileOpen} timeout="auto">
                    <Stack spacing={0.2}>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item>
                          <Typography variant="subtitle1">
                            プロジェクト名
                          </Typography>
                        </Grid>
                      </Grid>
                      <ProjectOpen />
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item>
                          <Typography variant="subtitle1">杭リスト</Typography>
                        </Grid>
                      </Grid>
                      <PileFileOpen />
                      <Typography variant="subtitle1">工区</Typography>
                      <WorkArea />
                    </Stack>
                  </Collapse>
                </div>
                <Divider />
                <ListItemButton onClick={() => handleClick("meshOption")}>
                  <ListItemText primary="地盤" id="meshOption" />
                  {open.meshOption ? (
                    <ExpandLess id="meshOption" />
                  ) : (
                    <ExpandMore id="meshOption" />
                  )}
                </ListItemButton>
                <Collapse in={open.meshOption} timeout="auto" unmountOnExit>
                  <Stack spacing={0.5}>
                    <Typography variant="subtitle1">
                      メッシュ表示方法
                    </Typography>
                    <MeshOption />
                    <Typography variant="subtitle1">メッシュカラー</Typography>
                    <MeshColorOption />
                  </Stack>
                </Collapse>
                <Divider />
                <ListItemButton onClick={() => handleClick("pileOption")}>
                  <ListItemText primary="杭" id="pileOption" />
                  {open.pileOption ? (
                    <ExpandLess id="pileOption" />
                  ) : (
                    <ExpandMore id="pileOption" />
                  )}
                </ListItemButton>

                <Collapse in={open.pileOption} timeout="auto" unmountOnExit>
                  <Stack spacing={0.5}>
                    <Typography variant="subtitle1">必要根入れ長さ</Typography>
                    <MarginOption />
                    <Typography variant="subtitle1">余長の判定基準</Typography>
                    <PileFilter />
                    <PileVisivility />
                  </Stack>
                </Collapse>
              </Collapse>
            </Stack>
          </CardContent>
        </Card>
      </LeftPanelContainer>
    </ThemeProvider>
  );
};

export default LeftPanel;
