import { red, blue, yellow, grey } from "@mui/material/colors";
// Types
import { PileFilterType, MarginOption, WorkArea } from "../../types/pile.types";

const names: string[] = ["不足", "小", "中", "大"];

// const colors = [red[500], yellow[700], grey[600], blue["A700"]];
const colors: string[] = ["red", "#ffe400", "grey", "#0026ff"];
const panelColors: string[] = [red[500], yellow[700], grey[600], blue["A700"]];

const initialPileFilter: PileFilterType = {
  // 杭のステータスわけ
  range: [0, 1000, 5000],
  // 杭の表示ステータス
  visibleStatus: names,
};

const initialMarginOption: MarginOption = {
  // 計算方法
  method: 1,
  // パラメータ１
  param1: 1000,
  // パラメータ２
  param2: 0,
  // パラメータ３
  param3: 1,
  // パラメータ４
  param4: 0,
};

const initialWorkArea: WorkArea[] = [
  {
    name: "全て",
    key: "all",
    index: 0,
  },
];

export {
  names,
  colors,
  panelColors,
  initialPileFilter,
  initialMarginOption,
  initialWorkArea,
};
