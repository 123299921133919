import { useRef } from "react";
import { Typography, Button, Input } from "@mui/material";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";

const styles = {
  hidden: {
    display: "none",
  },
  importLabel: {
    color: "black",
  },
};

type NewProjectFileUploadProps = {
  file: File | null;
  handleChange: (e: React.ChangeEvent<any>) => void;
};

const NewProjectFileUpload = ({
  file,
  handleChange,
}: NewProjectFileUploadProps) => {
  const fileRef = useRef(null);
  return (
    <>
      <Button
        component="label"
        variant="contained"
        color="success"
        startIcon={<DriveFolderUploadIcon />}
        fullWidth
      >
        <Input
          type="file"
          id="csvFileInput"
          inputProps={{
            accept:
              ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
          }}
          ref={fileRef}
          onChange={handleChange}
          style={styles.hidden}
        />
        CSVを選択
      </Button>
      <Typography variant="caption" color={file ? "text.secondary" : "error"}>
        {file ? file.name : "ファイルを選択してください"}
      </Typography>
    </>
  );
};

export default NewProjectFileUpload;
