import { useMemo } from "react";

type AnnotationProps = {
  children: string;
  position: [number, number, number];
  opacity?: number;
  color?: string;
  fontSize?: number;
  visible?: boolean;
  align?: "center" | "left" | "right";
  txtStyle?: "info" | "date";
  yOffset?: number;
  scale?: [number, number, number];
};

const Annotation = ({
  children,
  position,
  opacity,
  color = "black",
  fontSize = 15,
  visible = true,
  align = "center",
  txtStyle = "info",
  yOffset = 0,
  scale=[20,12,12],
}: AnnotationProps) => {
  const canvas = useMemo(() => {
    var fontface = "monospace, serif";
    var fontsize = fontSize;
    var borderThickness = 4;
    var canvas = document.createElement("canvas");
    var context = canvas.getContext("2d");
    if (!context) return canvas;
    canvas.width = 450;
    canvas.height = 270;

    // // canvasの大きさ
    // context.rect(0, 0, canvas.width, canvas.height);
    // context.strokeStyle = "black";
    // context.lineWidth = 10;
    // context.stroke();

    // 改行があるテキストを複数行に分解
    var textvalArr = toMultiLine(children);

    // 文字の幅の最大値を取得
    // const textWidthArr = textvalArr.map((text) => {
    //   return context.measureText(text).width;
    // });
    const textWidthArr = textvalArr.map((text) => {
      if (!context) return 0;
      return context.measureText(text).width;
    });
    const textWidth =
      textWidthArr.length > 1 ? Math.max(...textWidthArr) : textWidthArr[0];
    // テキストを配置するX座標
    const contextX = txtStyle === "info"? canvas.width / 2 + 1.2: canvas.width / 2;
    // キャンバスの大きさを四角で表示
    if (txtStyle === "date" && textvalArr[0].length>0) {
      const rectX = canvas.width / 2 - textWidth*1.25;
      const rectY = fontSize+yOffset;
      context.fillStyle = "rgba(100, 100, 100, 0.5)";
      context.strokeRect(rectX, rectY, textWidth*2.5, fontSize);
      context.fillStyle = "rgba(230, 230, 230, 0.5)";
      context.fillRect(rectX, rectY, textWidth*2.5, fontSize);
      context.stroke();
    }

    context.textAlign = align;
    context.textBaseline = "hanging";
    context.font = `${fontSize}px ${fontface}`;
    context.lineWidth = borderThickness;

    context.fillStyle = color;

    // draw each line on canvas.
    for (var i = 0; i < textvalArr.length; i++) {
      context.fillText(textvalArr[i], contextX, fontsize * (i + 1)+yOffset);
    }
    return canvas;
  }, [children]);

  return (
    <sprite scale={scale} position={position} visible={visible}>
      <spriteMaterial attach="material" transparent alphaTest={0.5}>
        <canvasTexture attach="map" image={canvas} />
      </spriteMaterial>
    </sprite>
  );
};

// Creates an array where the <br/> tag splits the values.
function toMultiLine(text: string): string[] {
  var textArr = [];
  // var textArr = new Array();
  text = text.replace(/\n\r?/g, "<br/>");
  textArr = text.split("<br/>");
  return textArr;
}

export default Annotation;
