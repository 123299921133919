import { useState, useContext } from "react";
import {
  Stack,
  Typography,
  Button,
  DialogContent,
  DialogActions,
} from "@mui/material";
// Ampify
import { API } from "aws-amplify";
import { createPileSet } from "../../graphql/mutations";
import { listPileSetsByProjectId } from "../../graphql/queries";
// Contexts
import UploadStatusContext from "../contexts/UploadStatusContext";
import ProgressContext from "../contexts/ProgressContext";
import ProjectContext from "../contexts/ProjectContext";
import ObjectContext from "../contexts/ObjectContext";

// Components
import NewProjectFileUpload from "./NewProjectFileUpload";
import useFillEmptyDesignDialog from "./useFillEmptyDesignDialog";
// Utilities
import { typeCSVforPileInput, PileInputToPile, fillPlanValue, isSoilDepthDesignEmpty } from "../utils/pileUtils";
import { createPileGeometry } from "../utils/objectUtils";
import {
  fileUploadErrorAlert,
  fileValidationErrorAlert,
} from "../utils/alertUtils";
import { csvFileToObjectArray } from "../utils/csvUtils";
import { PileCSVType, PileDataType, PileType } from "../../types/pile.types";

type NewPileSetProps = {
  handleClick: (action: string) => void;
};

const NewPileSet = ({ handleClick }: NewPileSetProps) => {
  // Contexts
  const { setUploadStatusProvided } = useContext(UploadStatusContext);
  const { setProgressProvided } = useContext(ProgressContext);
  const { projectProvided, setProjectProvided } = useContext(ProjectContext);
  const { setObjectProvided } = useContext(ObjectContext);
  // Validation
  const [inputError, setInputError] = useState({
    pileFile: true,
  });

  // Pile File
  const [pileFile, setPileFile] = useState(null);
  const handlePileFileChange = (e: React.ChangeEvent<any>) => {
    if (e.target.files[0] === undefined) {
      setPileFile(null);
      setInputError({ ...inputError, pileFile: true });
      return;
    }
    setPileFile(e.target.files[0]);
    setInputError({ ...inputError, pileFile: false });
  };
  // Dialog
  const { FillEmptyDesignDialog, openFillEmptyDesignDialog } = useFillEmptyDesignDialog();

  // ボタンを押したときの処理
  const createNewPileSet = async () => {
    if (pileFile) {
      // convert mesh and pile csv file to object array
      let pileObjectArray = (await (async () => {
        try {
          return await csvFileToObjectArray(pileFile, "pile");
        } catch (e) {
          fileValidationErrorAlert("pile");
          throw new Error("pile file validation error");
        }
      })()) as PileCSVType[];
      // check if 支持層深度（設計）and 根入れ余長（設計）are empty
      if (
        isSoilDepthDesignEmpty(pileObjectArray)
      ) {
        const result = await openFillEmptyDesignDialog();
        switch (result) {
          case "yes":
            // fill empty design
            pileObjectArray = fillPlanValue(pileObjectArray);
            break;
          case "no":
            break;
        }
      }
      const pileObjectArrayWithType = typeCSVforPileInput(pileObjectArray);
      try {
        const project = projectProvided.project;
        // create pile set
        const pileSetRes = (await API.graphql({
          query: createPileSet,
          variables: {
            projectId: project.id,
            pilesInput: pileObjectArrayWithType,
          },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        })) as any;
        const pileSet = pileSetRes.data.createPileSet;
        const pileSets = (await API.graphql({
          query: listPileSetsByProjectId,
          variables: { projectId: project.id },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        })) as any;
        console.log(project.id);
        console.log(pileSet.id);
        // create pile object
        const piles = PileInputToPile(pileObjectArrayWithType);
        const pilePoints = piles.map((pile: PileType) => {
          return { props: pile } as PileDataType;
        });
        const { pileObjectArray: pileObjectArrayWithInfo, pointsAddToMesh } =
          createPileGeometry(pilePoints);
        setProjectProvided({
          ...projectProvided,
          project,
          isPileNewest: true,
          pileFiles: pileSets.data.listPileSetsByProjectId,
          selectedPile: pileSet,
        });
        setObjectProvided({
          type: "updatePileSet",
          payload: {
            mesh: {
              pointsAddToMesh,
            },
            piles: pileObjectArrayWithInfo,
          },
        });
        // close dialog
        setUploadStatusProvided(true);
        handleClick("close");
      } catch (e) {
        console.log(e);
        fileUploadErrorAlert();
      }
    }
  };

  return (
    <>
      <DialogContent>
        <Stack spacing={1.0}>
          <Typography variant="subtitle1">新しい杭リスト</Typography>
          <NewProjectFileUpload
            file={pileFile}
            handleChange={handlePileFileChange}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          disabled={Object.values(inputError).some((item) => item)}
          onClick={async () => {
            setProgressProvided(true);
            try {
              await createNewPileSet();
            } catch (e) {
              console.log(e);
            }
            setProgressProvided(false);
          }}
        >
          アップロード
        </Button>
      </DialogActions>
      <FillEmptyDesignDialog
        title="確認"
        message="支持層深度（設計）・根入れ余長（設計）の列が空白です。ANAGO Mobileで計算した値を自動入力しますか？"
      />
    </>
  );
};

export default NewPileSet;
