import {
  FormControl,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  Grid,
  Stack,
  SelectChangeEvent,
} from "@mui/material";
import { ReactNode, useContext } from "react";
// Contexts
import ObjectContext from "../contexts/ObjectContext";
import ProjectContext from "../contexts/ProjectContext";
// Utilities
import { fileUploadErrorAlert } from "../utils/alertUtils";
// Ampify
import { API } from "aws-amplify";
import { updateProjectSettings } from "../../graphql/mutations";
// Types
import { MarginOptionType } from "../../types/pile.types";
import { Project, ProjectSettings } from "../../types/project.types";

const MarginOption = () => {
  // Context
  const { projectProvided } = useContext(ProjectContext);
  const { objectProvided, setObjectProvided } = useContext(ObjectContext);
  // Change Pile File
  const handleChange = async (
    event:
      | SelectChangeEvent<number>
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const project = projectProvided.project;
    const newOption: MarginOptionType = {
      ...objectProvided.pileMarginOption,
    };
    const projectSettingsInput = {} as ProjectSettings;
    const name = event.target.name as keyof MarginOptionType;
    const value = event.target.value as number | string;
    switch (name) {
      case "method":
        newOption.method = Number(value);
        projectSettingsInput.pileIndexParam = Number(value);
        break;
      case "param1":
        newOption.param1 = Number(value);
        projectSettingsInput.pileParamA = Number(value);
        break;
      case "param2":
        newOption.param2 = Number(value);
        projectSettingsInput.pileParamB = Number(value);
        break;
      case "param3":
        newOption.param3 = Number(value);
        projectSettingsInput.pileParamC = Number(value);
        break;
      case "param4":
        newOption.param4 = Number(value);
        projectSettingsInput.pileParamD = Number(value);
        break;
      default:
        console.log("invalid type");
    }
    setObjectProvided({ type: "setPileMarginOption", payload: newOption });
    // skip if empty
    if (event.target.value === "") {
      return;
    }
    try {
      // update project settings
      await API.graphql({
        query: updateProjectSettings,
        variables: {
          projectId: project.id,
          projectSettingsInput,
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
    } catch (e) {
      fileUploadErrorAlert();
      console.log(e);
    }
  };
  return (
    <Stack spacing={0.2}>
      <FormControl fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          name="method"
          value={objectProvided.pileMarginOption.method}
          size="small"
          onChange={handleChange}
        >
          <MenuItem value={0}>長さ+a</MenuItem>
          <MenuItem value={1}>直径×a+b</MenuItem>
        </Select>
      </FormControl>
      {objectProvided.pileMarginOption.method === 0 && (
        <div>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="param2"
                name="param2"
                label="+a"
                variant="outlined"
                type="number"
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">mm</InputAdornment>
                  ),
                }}
                fullWidth
                value={objectProvided.pileMarginOption.param2}
                margin="normal"
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </div>
      )}
      {objectProvided.pileMarginOption.method === 1 && (
        <div>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={6}>
              <TextField
                id="param3"
                name="param3"
                label="×a"
                variant="outlined"
                type="number"
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end"></InputAdornment>
                  ),
                }}
                fullWidth
                value={objectProvided.pileMarginOption.param3}
                margin="normal"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="param4"
                name="param4"
                label="+b"
                variant="outlined"
                type="number"
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">mm</InputAdornment>
                  ),
                }}
                fullWidth
                value={objectProvided.pileMarginOption.param4}
                margin="normal"
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </div>
      )}
    </Stack>
  );
};

export default MarginOption;
