import { createContext, useReducer, FC, ReactNode } from "react";
// Types
type Props = {
  children: ReactNode;
};
type ProgressContextType = boolean;
type ActionType = boolean;
type ProgressContextValue = {
  progressProvided: ProgressContextType;
  setProgressProvided: React.Dispatch<ActionType>;
};

const ProgressContext = createContext<ProgressContextValue>({
  progressProvided: false as ProgressContextType,
  setProgressProvided: () => {},
});

const initialProgress: ProgressContextType = false;
const ProgressReducer = (
  initialProgress: boolean,
  action: ActionType
): ProgressContextType => {
  return action;
};

const ProgressProvider: FC<Props> = ({ children }) => {
  const [progress, setProgress] = useReducer(ProgressReducer, initialProgress);
  return (
    <ProgressContext.Provider
      value={{
        progressProvided: progress,
        setProgressProvided: setProgress,
      }}
    >
      {children}
    </ProgressContext.Provider>
  );
};

export default ProgressContext;
export { ProgressProvider, initialProgress };
